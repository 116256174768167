import React from "react";
import {
  Box,
  ColumnLayout,
  Container,
  Header,
  Spinner
} from "@cloudscape-design/components";
import * as R from "ramda";
import {
  useResourcesAccountMetadata,
  useResourcesMetadata,
  useResourcesRegionMetadata
} from "../../Hooks/useResourcesMetadata";
import ValueWithLabel from "../../Shared/ValueWithLabel";
const ResourceOverview = () => {
  const { data: resources = { accounts: [] }, isLoading: loadingResources } = useResourcesMetadata();
  const accountsFilter = resources.accounts.map(({ accountId }) => ({ accountId }));
  const { data: accounts = [], isLoading: loadingAccounts } = useResourcesAccountMetadata(accountsFilter, { batchSize: 50 });
  const { data: regions = [], isLoading: loadingRegions } = useResourcesRegionMetadata(accountsFilter, { batchSize: 50 });
  const regionCount = R.reduce((acc, val) => R.add(acc, R.length(val.regions)), 0, regions);
  return /* @__PURE__ */ React.createElement(Container, { "data-testid": "resources-metadata-overview", header: /* @__PURE__ */ React.createElement(Header, null, "Overview") }, loadingAccounts || loadingRegions || loadingResources ? /* @__PURE__ */ React.createElement(Spinner, null) : /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Resources discovered" }, /* @__PURE__ */ React.createElement(Box, { tagOverride: "p", variant: "h1" }, resources.count ?? "-")), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Resources types" }, /* @__PURE__ */ React.createElement(Box, { tagOverride: "p", variant: "h1" }, resources.resourceTypes?.length ?? "-")), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Accounts" }, /* @__PURE__ */ React.createElement(Box, { tagOverride: "p", variant: "h1" }, R.length(accounts))), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Regions" }, /* @__PURE__ */ React.createElement(Box, { tagOverride: "p", variant: "h1" }, regionCount))));
};
export default ResourceOverview;
