import React from "react";
import { SplitPanel } from "@cloudscape-design/components";
import { useResourceState } from "../../../Contexts/ResourceContext";
import MultipleResourceDetailsPanel from "./MultipleResourceDetailsPanel";
import * as R from "ramda";
import ResourceDetails from "../../../Shared/ResourceDetails";
const ResourcesSplitPanel = () => {
  const [{ resources }] = useResourceState({});
  const getDetailsPanel = () => {
    if (R.gt(R.length(resources), 1))
      return {
        header: `${resources.length} resources selected`,
        body: /* @__PURE__ */ React.createElement(MultipleResourceDetailsPanel, { resources })
      };
    else if (R.isEmpty(resources))
      return {
        header: "Select a resource",
        body: "Select a resource to see its details."
      };
    else
      return {
        header: R.head(resources).properties.title,
        body: /* @__PURE__ */ React.createElement(ResourceDetails, { selectedResource: R.head(resources) })
      };
  };
  const { header: panelHeader, body: panelBody } = getDetailsPanel();
  return /* @__PURE__ */ React.createElement(
    SplitPanel,
    {
      header: panelHeader,
      hidePreferencesButton: true,
      i18nStrings: {
        preferencesTitle: "Split panel preferences",
        preferencesPositionLabel: "Split panel position",
        preferencesPositionDescription: "Choose the default split panel position for the service.",
        preferencesPositionSide: "Side",
        preferencesPositionBottom: "Bottom",
        preferencesConfirm: "Confirm",
        preferencesCancel: "Cancel",
        closeButtonAriaLabel: "Close panel",
        openButtonAriaLabel: "Open panel",
        resizeHandleAriaLabel: "Resize split panel"
      }
    },
    panelBody
  );
};
export default ResourcesSplitPanel;
