import React from "react";
import {
  ColumnLayout,
  SpaceBetween
} from "@cloudscape-design/components";
import ValueWithLabel from "../../../../../components/Shared/ValueWithLabel";
const parseConfiguration = (configuration) => {
  try {
    return JSON.parse(JSON.parse(configuration));
  } catch (Error) {
    return JSON.parse(configuration);
  }
};
export const LoadBalancerItem = ({ configuration }) => {
  const parsedConfig = parseConfiguration(configuration);
  return /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 2, variant: "text-grid" }, /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Scheme" }, parsedConfig.scheme)), /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Type" }, parsedConfig.type ? parsedConfig.type : "Classic")));
};
export default LoadBalancerItem;
