export const services = [
  {
    "service": "Alexa for Business"
  },
  {
    "service": "Amazon AWS"
  },
  {
    "service": "Amazon Web Services",
    "filteringTags": ["AWS"]
  },
  {
    "service": "Amplify"
  },
  {
    "service": "API Gateway",
    "filteringTags": ["API GW"]
  },
  {
    "service": "Application Auto Scaling",
    "filteringTags": ["Autoscaling"]
  },
  {
    "service": "Application Discovery Service",
    "filteringTags": ["ADS"]
  },
  {
    "service": "AppFlow"
  },
  {
    "service": "App Mesh"
  },
  {
    "service": "AppStream 2.0"
  },
  {
    "service": "AppSync"
  },
  {
    "service": "Artifact"
  },
  {
    "service": "Athena"
  },
  {
    "service": "Audit Manager"
  },
  {
    "service": "Augmented AI"
  },
  {
    "service": "Aurora"
  },
  {
    "service": "Auto Scaling"
  },
  {
    "service": "Backint Agent"
  },
  {
    "service": "Backup"
  },
  {
    "service": "Batch"
  },
  {
    "service": "Billing and Cost Management"
  },
  {
    "service": "Blockchain Templates"
  },
  {
    "service": "Blox"
  },
  {
    "service": "Certificate Manager",
    "filteringTags": ["ACM"]
  },
  {
    "service": "Chalice"
  },
  {
    "service": "Chatbot"
  },
  {
    "service": "Chime"
  },
  {
    "service": "Client VPN"
  },
  {
    "service": "Cloud Development Kit",
    "filteringTags": ["CDK"]
  },
  {
    "service": "Cloud Digital Interface SDK"
  },
  {
    "service": "Cloud Directory"
  },
  {
    "service": "Cloud Map"
  },
  {
    "service": "Cloud9"
  },
  {
    "service": "CloudFormation",
    "filteringTags": ["CFN"]
  },
  {
    "service": "CloudFront"
  },
  {
    "service": "CloudHSM"
  },
  {
    "service": "CloudSearch"
  },
  {
    "service": "CloudShell"
  },
  {
    "service": "CloudTrail"
  },
  {
    "service": "CloudWatch"
  },
  {
    "service": "CloudWatch Events"
  },
  {
    "service": "CloudWatch Logs"
  },
  {
    "service": "CodeArtifact"
  },
  {
    "service": "CodeBuild"
  },
  {
    "service": "CodeCommit"
  },
  {
    "service": "CodeDeploy"
  },
  {
    "service": "CodeGuru"
  },
  {
    "service": "CodePipeline"
  },
  {
    "service": "CodeStar"
  },
  {
    "service": "Cognito"
  },
  {
    "service": "Command Line Interface",
    "filteringTags": ["CLI"]
  },
  {
    "service": "Comprehend"
  },
  {
    "service": "Compute Optimizer"
  },
  {
    "service": "Config"
  },
  {
    "service": "Connect"
  },
  {
    "service": "Control Tower"
  },
  {
    "service": "Console Mobile Application"
  },
  {
    "service": "Corretto"
  },
  {
    "service": "Data Exchange"
  },
  {
    "service": "Data Pipeline"
  },
  {
    "service": "DataSync"
  },
  {
    "service": "Database Migration Service",
    "filteringTags": ["DMS"]
  },
  {
    "service": "Deep Learning AMIs"
  },
  {
    "service": "Deep Learning Containers"
  },
  {
    "service": "DeepComposer"
  },
  {
    "service": "DeepLens"
  },
  {
    "service": "DeepRacer"
  },
  {
    "service": "Detective"
  },
  {
    "service": "Device Farm"
  },
  {
    "service": "DevPay"
  },
  {
    "service": "Direct Connect",
    "filteringTags": ["DC"]
  },
  {
    "service": "Directory Service"
  },
  {
    "service": "Directory Service for Microsoft AD"
  },
  {
    "service": "AD Connector"
  },
  {
    "service": "Simple AD"
  },
  {
    "service": "DocumentDB"
  },
  {
    "service": "DynamoDB",
    "filteringTags": ["DDB"]
  },
  {
    "service": "DynamoDB Streams",
    "filteringTags": ["DDB"]
  },
  {
    "service": "EC2 Auto Scaling"
  },
  {
    "service": "Elastic Beanstalk",
    "filteringTags": ["EB"]
  },
  {
    "service": "Elastic Block Store",
    "filteringTags": ["EBS"]
  },
  {
    "service": "Elastic Compute Cloud",
    "filteringTags": ["EC2"]
  },
  {
    "service": "Elastic Container Registry",
    "filteringTags": ["ECR"]
  },
  {
    "service": "Elastic Container Service",
    "filteringTags": ["ECS"]
  },
  {
    "service": "Elastic Kubernetes Service",
    "filteringTags": ["EKS"]
  },
  {
    "service": "Elastic File System",
    "filteringTags": ["EFS"]
  },
  {
    "service": "Elastic Inference",
    "filteringTags": ["EI"]
  },
  {
    "service": "Elastic Load Balancing",
    "filteringTags": ["ELB"]
  },
  {
    "service": "Elastic Transcoder"
  },
  {
    "service": "ElastiCache"
  },
  {
    "service": "Amazon Elasticsearch Service"
  },
  {
    "service": "Amazon OpenSearch Service"
  },
  {
    "service": "EMR"
  },
  {
    "service": "EventBridge"
  },
  {
    "service": "Fargate"
  },
  {
    "service": "Firewall Manager"
  },
  {
    "service": "Forecast"
  },
  {
    "service": "Fraud Detector"
  },
  {
    "service": "FreeRTOS"
  },
  {
    "service": "FSx"
  },
  {
    "service": "FSx for Lustre"
  },
  {
    "service": "FSx for Windows File Server"
  },
  {
    "service": "GameLift"
  },
  {
    "service": "AWS Global Accelerator"
  },
  {
    "service": "Glue"
  },
  {
    "service": "Grafana"
  },
  {
    "service": "Greengrass"
  },
  {
    "service": "Ground Station"
  },
  {
    "service": "Ground Truth"
  },
  {
    "service": "GovCloud (US)"
  },
  {
    "service": "GuardDuty"
  },
  {
    "service": "Health"
  },
  {
    "service": "HealthLake"
  },
  {
    "service": "Honeycode"
  },
  {
    "service": "Identity and Access Management",
    "filteringTags": ["IAM"]
  },
  {
    "service": "Image Builder"
  },
  {
    "service": "Import/Export"
  },
  {
    "service": "Inspector"
  },
  {
    "service": "IoT"
  },
  {
    "service": "IoT Analytics"
  },
  {
    "service": "IoT Core for LoRaWAN"
  },
  {
    "service": "IoT Device Defender"
  },
  {
    "service": "IoT Device Management"
  },
  {
    "service": "IoT Events"
  },
  {
    "service": "IoT SiteWise"
  },
  {
    "service": "IoT Things Graph"
  },
  {
    "service": "IoT Wireless"
  },
  {
    "service": "IoT 1-Click"
  },
  {
    "service": "IQ"
  },
  {
    "service": "Kendra"
  },
  {
    "service": "Key Management Service",
    "filteringTags": ["KMS"]
  },
  {
    "service": "Kinesis"
  },
  {
    "service": "Kinesis Data Analytics"
  },
  {
    "service": "Kinesis Data Firehose"
  },
  {
    "service": "Kinesis Video Streams"
  },
  {
    "service": "Lake Formation",
    "filteringTags": ["LF"]
  },
  {
    "service": "Lambda"
  },
  {
    "service": "Launch Wizard"
  },
  {
    "service": "Lex"
  },
  {
    "service": "License Manager"
  },
  {
    "service": "Lightsail"
  },
  {
    "service": "Local Zones"
  },
  {
    "service": "Location Service"
  },
  {
    "service": "Lookout for Equipment"
  },
  {
    "service": "Lookout for Metrics"
  },
  {
    "service": "Lookout for Vision"
  },
  {
    "service": "Lumberyard"
  },
  {
    "service": "Machine Learning"
  },
  {
    "service": "Macie"
  },
  {
    "service": "Managed Blockchain"
  },
  {
    "service": "Managed Service for Grafana"
  },
  {
    "service": "Managed Services"
  },
  {
    "service": "Managed Streaming for Apache Kafka",
    "filteringTags": ["MSK"]
  },
  {
    "service": "Management Console"
  },
  {
    "service": "Management Portal for vCenter"
  },
  {
    "service": "Marketplace"
  },
  {
    "service": "Mechanical Turk"
  },
  {
    "service": "MediaConnect"
  },
  {
    "service": "MediaConvert"
  },
  {
    "service": "MediaLive"
  },
  {
    "service": "MediaPackage"
  },
  {
    "service": "MediaStore"
  },
  {
    "service": "MediaTailor"
  },
  {
    "service": "Migration Hub"
  },
  {
    "service": "Mobile Analytics"
  },
  {
    "service": "Mobile Hub"
  },
  {
    "service": "Monitron"
  },
  {
    "service": "MQ"
  },
  {
    "service": "MXNet"
  },
  {
    "service": "Neptune"
  },
  {
    "service": "Network Firewall"
  },
  {
    "service": "OpsWorks"
  },
  {
    "service": "OpsWorks Stacks"
  },
  {
    "service": "OpsWorks for Chef Automate"
  },
  {
    "service": "Organizations"
  },
  {
    "service": "Outposts"
  },
  {
    "service": "Panorama"
  },
  {
    "service": "ParallelCluster"
  },
  {
    "service": "Personal Health Dashboard"
  },
  {
    "service": "Personalize"
  },
  {
    "service": "Pinpoint"
  },
  {
    "service": "Polly"
  },
  {
    "service": "Private Certificate Authority",
    "filteringTags": ["PCA"]
  },
  {
    "service": "Prometheus"
  },
  {
    "service": "Proton"
  },
  {
    "service": "QLDB"
  },
  {
    "service": "QuickSight"
  },
  {
    "service": "Reachability Analyzer"
  },
  {
    "service": "Redshift"
  },
  {
    "service": "Rekognition"
  },
  {
    "service": "Relational Database Service",
    "filteringTags": ["RDS"]
  },
  {
    "service": "Remote Configuration TBR"
  },
  {
    "service": "Resource Groups"
  },
  {
    "service": "Resource Access Manager",
    "filteringTags": ["RAM"]
  },
  {
    "service": "RoboMaker"
  },
  {
    "service": "Route 53",
    "filteringTags": ["R53"]
  },
  {
    "service": "SageMaker"
  },
  {
    "service": "Schema Conversion Tool",
    "filteringTags": ["SCT"]
  },
  {
    "service": "Secrets Manager"
  },
  {
    "service": "Security Hub"
  },
  {
    "service": "Security Token Service",
    "filteringTags": ["STS"]
  },
  {
    "service": "Server Migration Service"
  },
  {
    "service": "Serverless Application Model",
    "filteringTags": ["SAM"]
  },
  {
    "service": "Serverless Application Repository",
    "filteringTags": ["SAR"]
  },
  {
    "service": "Service Catalog"
  },
  {
    "service": "Service Health Dashboard"
  },
  {
    "service": "Service Quotas"
  },
  {
    "service": "Shield"
  },
  {
    "service": "Signer"
  },
  {
    "service": "Silk"
  },
  {
    "service": "Simple Email Service",
    "filteringTags": ["SES"]
  },
  {
    "service": "Simple Notification Service",
    "filteringTags": ["SNS"]
  },
  {
    "service": "Simple Queue Service",
    "filteringTags": ["SQS"]
  },
  {
    "service": "Simple Storage Service",
    "filteringTags": ["S3"]
  },
  {
    "service": "Simple Storage Service Glacier",
    "filteringTags": ["S3"]
  },
  {
    "service": "Simple Workflow Service",
    "filteringTags": ["SWS"]
  },
  {
    "service": "SimpleDB"
  },
  {
    "service": "Single Sign-On"
  },
  {
    "service": "Site-to-Site VPN"
  },
  {
    "service": "Snowball"
  },
  {
    "service": "Snowcone"
  },
  {
    "service": "Snowmobile"
  },
  {
    "service": "Step Functions"
  },
  {
    "service": "Storage Gateway"
  },
  {
    "service": "Sumerian"
  },
  {
    "service": "Support"
  },
  {
    "service": "Systems Manager"
  },
  {
    "service": "Textract"
  },
  {
    "service": "Timestream"
  },
  {
    "service": "Tools for PowerShell"
  },
  {
    "service": "Transcribe"
  },
  {
    "service": "Transfer Family"
  },
  {
    "service": "Transit Gateway",
    "filteringTags": ["TGW"]
  },
  {
    "service": "Translate"
  },
  {
    "service": "Trusted Advisor",
    "filteringTags": ["TA"]
  },
  {
    "service": "Virtual Private Cloud",
    "filteringTags": ["VPC"]
  },
  {
    "service": "WAF",
    "filteringTags": ["Web Application Firewall"]
  },
  {
    "service": "Wavelength"
  },
  {
    "service": "Well-Architected Tool"
  },
  {
    "service": "WorkDocs"
  },
  {
    "service": "WorkLink"
  },
  {
    "service": "WorkMail"
  },
  {
    "service": "WorkSpaces"
  },
  {
    "service": "WAM",
    "filteringTags": ["WorkSpaces Application Manager"]
  },
  {
    "service": "X-Ray"
  }
];
