import React from "react";
import {
  Box,
  TextFilter,
  Header,
  SpaceBetween,
  Table,
  Pagination
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import * as R from "ramda";
const ViewExplorerAccountsTable = ({ selectedView }) => {
  const {
    items,
    filterProps,
    collectionProps,
    paginationProps
  } = useCollection(R.pathOr([], ["accounts"], selectedView), {
    filtering: {
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No Accounts"), /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No Accounts to display")),
      noMatch: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No match"), /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No Accounts matched."))
    },
    pagination: { pageSize: 5 },
    sorting: {}
  });
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l", direction: "vertical" }, /* @__PURE__ */ React.createElement(
    Table,
    {
      ...collectionProps,
      trackBy: "accountId",
      ariaLabels: {
        tableLabel: "Accounts"
      },
      columnDefinitions: [
        {
          id: "accountId",
          header: "Account Id",
          cell: (e) => e.accountId
        }
      ],
      items,
      loadingText: "Loading accounts",
      visibleColumns: ["accountId", "regions", "resourceCount"],
      filter: /* @__PURE__ */ React.createElement(TextFilter, { ...filterProps, filteringPlaceholder: "Find an Account" }),
      pagination: /* @__PURE__ */ React.createElement(Pagination, { ...paginationProps }),
      header: /* @__PURE__ */ React.createElement(Header, null, "AWS Accounts")
    }
  ));
};
export default ViewExplorerAccountsTable;
