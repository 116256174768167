import React from "react";
import {
  Box
} from "@cloudscape-design/components";
import PropTypes from "prop-types";
const ValueWithLabel = ({ label, children }) => /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Box, { margin: { bottom: "xxxs" }, color: "text-label" }, label), /* @__PURE__ */ React.createElement("div", null, children));
ValueWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.object
};
export default ValueWithLabel;
