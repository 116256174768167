import React from "react";
import { HelpPanel } from "@cloudscape-design/components";
const PlaceholderHelp = () => {
  return /* @__PURE__ */ React.createElement(
    HelpPanel,
    {
      header: /* @__PURE__ */ React.createElement("h2", null, "Work in progress")
    },
    /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", null, "We are still building the help panel content for this page. It will be available soon."))
  );
};
export default PlaceholderHelp;
