import React from "react";
import { ResourceProvider } from "./components/Contexts/ResourceContext";
import { resourceReducer } from "./components/Contexts/Reducers/ResourceReducer";
import {
  QueryClient,
  QueryClientProvider
} from "react-query";
import PolarisLayout from "./PolarisLayout";
import {
  BrowserRouter as Router
} from "react-router-dom";
import { NotificationProvider } from "./components/Contexts/NotificationContext";
import { DiagramSettingsProvider } from "./components/Contexts/DiagramSettingsContext";
import { diagramSettingsReducer } from "./components/Contexts/Reducers/DiagramSettingsReducer";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
dayjs.extend(localizedFormat);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: 6e4,
      refetchOnWindowFocus: false,
      retry: 1
    }
  }
});
const Main = () => {
  const initialResourceState = {
    graphResources: [],
    resources: []
  };
  const initialDiagramSettingsState = {
    canvas: null,
    selectedResources: null,
    resources: []
  };
  return /* @__PURE__ */ React.createElement(QueryClientProvider, { client: queryClient }, /* @__PURE__ */ React.createElement(NotificationProvider, null, /* @__PURE__ */ React.createElement(
    DiagramSettingsProvider,
    {
      initialState: initialDiagramSettingsState,
      reducer: diagramSettingsReducer
    },
    /* @__PURE__ */ React.createElement(
      ResourceProvider,
      {
        initialState: initialResourceState,
        reducer: resourceReducer
      },
      /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(PolarisLayout, null))
    )
  )));
};
export default Main;
