import React from "react";
import { Box, Header, Table } from "@cloudscape-design/components";
import PropTypes from "prop-types";
import * as R from "ramda";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
function ComparisonType(comparisonType) {
  return /* @__PURE__ */ React.createElement("b", null, comparisonType);
}
const MultipleResourceDetailsPanel = ({ resources }) => {
  const keyHeaderMap = {
    resourceType: "Resource type",
    accountId: "Account Id",
    awsRegion: "Region",
    availabilityZone: "Availability zone"
  };
  const transformedData = [
    "resourceType",
    "accountId",
    "awsRegion",
    "availabilityZone"
  ].map((key) => {
    const data = { comparisonType: keyHeaderMap[key] };
    R.map((e) => data[e.id] = e.properties[key], resources);
    return data;
  });
  const columnDefinitions = [
    {
      id: "comparisonType",
      header: "",
      cell: ({ comparisonType }) => ComparisonType(comparisonType)
    },
    ...resources.map(({ id, properties }) => ({
      id,
      header: properties.title,
      cell: (item) => Array.isArray(item[id]) ? item[id].join(", ") : item[id]
    }))
  ];
  return /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "l" } }, /* @__PURE__ */ React.createElement(
    Table,
    {
      ariaLabels: {
        tableLabel: "Resources Details"
      },
      header: /* @__PURE__ */ React.createElement(Header, { variant: "h2" }, "Resource details"),
      items: transformedData,
      columnDefinitions,
      variant: "embedded"
    }
  ));
};
MultipleResourceDetailsPanel.propTypes = {
  resources: PropTypes.array.isRequired
};
export default MultipleResourceDetailsPanel;
