import ResourcesHelper from "./components/Explore/Resources/Utils/ResourcesHelper";
import ImportRegionHelper from "./components/RegionManagement/SinglePageImport/ImportRegionHelper";
import React from "react";
import ViewFormHelper from "./components/Explore/Views/ViewForm/ViewFormHelper";
import ViewExplorerHelper from "./components/Explore/Views/ViewExplorerHelper";
import DiscoverableAccountsAndRegionsHelper from "./components/RegionManagement/DiscoverableRegions/DiscoverableAccountsAndRegionsHelper";
import OpenDiagramHelper from "./components/Diagrams/Draw/DrawDiagram/OpenDiagram/OpenDiagramHelper";
import CreateDiagramHelper from "./components/Diagrams/Draw/DrawDiagram/CreateDiagram/CreateDiagramHelper";
import {
  ACCOUNTS,
  COST_REPORT,
  COSTS,
  CREATE_DIAGRAM,
  CREATE_VIEW,
  DIAGRAM_MANAGEMENT,
  EDIT_VIEW,
  IMPORT,
  OPEN_DIAGRAM,
  RESOURCES,
  VIEW,
  VIEWS
} from "./routes";
import DiagramExplorerHelper from "./components/Diagrams/Management/DiagramExplorerHelper";
import CostReportHelper from "./components/Costs/Report/CostReportHelper";
import CostExplorerHelper from "./components/Costs/QueryBuilder/CostExplorerHelper";
const panels = [
  {
    title: "Resources",
    path: RESOURCES,
    component: /* @__PURE__ */ React.createElement(ResourcesHelper, null)
  },
  {
    title: "Edit a View",
    path: EDIT_VIEW,
    component: /* @__PURE__ */ React.createElement(ViewFormHelper, null)
  },
  {
    title: "Create a View",
    path: CREATE_VIEW,
    component: /* @__PURE__ */ React.createElement(ViewFormHelper, null)
  },
  {
    title: "Explore Views",
    path: VIEWS,
    component: /* @__PURE__ */ React.createElement(ViewExplorerHelper, null)
  },
  {
    title: "Explore View",
    path: VIEW,
    component: /* @__PURE__ */ React.createElement(ViewExplorerHelper, null)
  },
  {
    title: "Import",
    path: IMPORT,
    component: /* @__PURE__ */ React.createElement(ImportRegionHelper, null)
  },
  {
    title: "Accounts",
    path: ACCOUNTS,
    component: /* @__PURE__ */ React.createElement(DiscoverableAccountsAndRegionsHelper, null)
  },
  {
    title: "Draw Diagram",
    path: OPEN_DIAGRAM,
    component: /* @__PURE__ */ React.createElement(OpenDiagramHelper, null)
  },
  {
    title: "Create Diagram",
    path: CREATE_DIAGRAM,
    component: /* @__PURE__ */ React.createElement(CreateDiagramHelper, null)
  },
  {
    title: "Diagram Management",
    path: DIAGRAM_MANAGEMENT,
    component: /* @__PURE__ */ React.createElement(DiagramExplorerHelper, null)
  },
  {
    title: "Cost Report",
    path: COST_REPORT,
    component: /* @__PURE__ */ React.createElement(CostReportHelper, null)
  },
  {
    title: "Cost Explorer",
    path: COSTS,
    component: /* @__PURE__ */ React.createElement(CostExplorerHelper, null)
  }
];
export default panels;
