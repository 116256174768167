import React from "react";
import AttributeEditor from "@cloudscape-design/components/attribute-editor";
function getLimit(limit, items) {
  if (limit) {
    if (items.length === limit) {
      return `You have reached the limit of ${limit} item(s)`;
    } else
      return `You can add up to ${limit} item(s)`;
  }
  return void 0;
}
export const AttributeCreator = ({
  item,
  items,
  limit,
  itemAdded,
  itemRemoved,
  definition
}) => {
  return /* @__PURE__ */ React.createElement(
    AttributeEditor,
    {
      onAddButtonClick: itemAdded,
      onRemoveButtonClick: ({ detail: { itemIndex } }) => itemRemoved(itemIndex),
      items,
      definition,
      addButtonText: `Add ${item}`,
      disableAddButton: items.length === limit,
      removeButtonText: "Remove",
      additionalInfo: getLimit(limit, items),
      empty: limit ? `Add upto ${limit} items` : void 0
    }
  );
};
export default AttributeCreator;
