const isLocalhost = Boolean(
  window.location.hostname === "localhost" || // [::1] is the IPv6 localhost address.
  window.location.hostname === "[::1]" || // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4]\/d|[01]?\/d\/d?)){3}$/
  )
);
export function register(config) {
  if (import.meta.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    const publicUrl = new URL("/", window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }
    window.addEventListener("load", () => {
      const swUrl = `./service-worker.js`;
      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        navigator.serviceWorker.ready.then(() => {
          console.log(
            "This web app is being served cache-first by a service worker. To learn more, visit https://bit.ly/CRA-PWA"
          );
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}
function registerValidSW(swUrl, config) {
  navigator.serviceWorker.register(swUrl).then((registration) => {
    registration.onupdatefound = () => {
      const installingWorker = registration.installing;
      if (installingWorker == null) {
        return;
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === "installed") {
          if (navigator.serviceWorker.controller) {
            console.log(
              "New content is available and will be used when all tabs for this page are closed. See https://bit.ly/CRA-PWA."
            );
            checkConfigUpdate(config, registration);
          } else {
            console.log("Content is cached for offline use.");
            checkConfigSuccess(config, registration);
          }
        }
      };
    };
  }).catch((error) => {
    console.error("Error during service worker registration:", error);
  });
}
function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl).then((response) => {
    const contentType = response.headers.get("content-type");
    if (response.status === 404 || contentType != null && contentType.indexOf("javascript") === -1) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          window.location.reload();
        });
      });
    } else {
      registerValidSW(swUrl, config);
    }
  }).catch(() => {
    console.log(
      "No internet connection found. App is running in offline mode."
    );
  });
}
function checkConfigUpdate(config, registration) {
  if (config?.onUpdate) {
    config.onUpdate(registration);
  }
}
function checkConfigSuccess(config, registration) {
  if (config?.onSuccess) {
    config.onSuccess(registration);
  }
}
export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
