export const getGroupedLayout = () => {
  return {
    name: "cola",
    animate: true,
    // whether to show the layout as it's running
    refresh: 1,
    // number of ticks per frame; higher is faster but more jerky
    maxSimulationTime: 1500,
    // max length in ms to run the layout
    ungrabifyWhileSimulating: false,
    // so you can't drag nodes during layout
    fit: true,
    // on every layout reposition of nodes, fit the viewport
    padding: 0,
    // padding around the simulation
    boundingBox: void 0,
    // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
    nodeDimensionsIncludeLabels: true,
    // whether labels should be included in determining the space used by a node
    // layout event callbacks
    ready: function() {
    },
    // on layoutready
    stop: function() {
    },
    // on layoutstop
    // positioning options
    randomize: true,
    // use random node positions at beginning of layout
    avoidOverlap: true,
    // if true, prevents overlap of node bounding boxes
    handleDisconnected: true,
    // if true, avoids disconnected components from overlapping
    convergenceThreshold: 0.01,
    // when the alpha value (system energy) falls below this value, the layout stops
    nodeSpacing: function() {
      return 5;
    },
    // extra spacing around nodes
    flow: void 0,
    // use DAG/tree flow layout if specified, e.g. { axis: 'y', minSeparation: 30 }
    alignment: void 0,
    // relative alignment constraints on nodes, e.g. {vertical: [[{node: node1, offset: 0}, {node: node2, offset: 5}]], horizontal: [[{node: node3}, {node: node4}], [{node: node5}, {node: node6}]]}
    gapInequalities: void 0,
    // list of inequality constraints for the gap between the nodes, e.g. [{"axis":"y", "left":node1, "right":node2, "gap":25}]
    centerGraph: true,
    // adjusts the node positions initially to center the graph (pass false if you want to start the layout from the current position)
    // different methods of specifying edge length
    // each can be a constant numerical value or a function like `function( edge ){ return 2; }`
    edgeLength: void 0,
    // sets edge length directly in simulation
    edgeSymDiffLength: void 0,
    // symmetric diff edge length in simulation
    edgeJaccardLength: void 0,
    // jaccard edge length in simulation
    // iterations of cola algorithm; uses default values on undefined
    unconstrIter: void 0,
    // unconstrained initial layout iterations
    userConstIter: void 0,
    // initial layout iterations with user-specified constraints
    allConstIter: void 0
    // initial layout iterations with all constraints including non-overlap
  };
};
