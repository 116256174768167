import React from "react";
import { Container, SpaceBetween, Header } from "@cloudscape-design/components";
import DiagramTable from "./DiagramTable";
import Breadcrumbs from "../../../Utils/Breadcrumbs";
import { DIAGRAM_MANAGEMENT } from "../../../routes";
const DiagramExplorer = () => {
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l", direction: "vertical" }, /* @__PURE__ */ React.createElement(Breadcrumbs, { items: [{ text: "Diagrams", href: DIAGRAM_MANAGEMENT }] }), /* @__PURE__ */ React.createElement(DiagramTable, null), /* @__PURE__ */ React.createElement(Container, { header: /* @__PURE__ */ React.createElement(Header, { variant: "h2" }, "Preview") }, /* @__PURE__ */ React.createElement(
    "div",
    {
      id: "preview",
      style: {
        width: "100%",
        height: "100%",
        minHeight: "400px",
        border: "1px solid rgba(224, 224, 224, 1)"
      }
    }
  )));
};
export default DiagramExplorer;
