import React from "react";
import { BreadcrumbGroup } from "@cloudscape-design/components";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { HOMEPAGE_PATH } from "../routes";
const Breadcrumbs = ({ items = [] }) => {
  const history = useHistory();
  return /* @__PURE__ */ React.createElement(
    BreadcrumbGroup,
    {
      onFollow: (e) => {
        e.preventDefault();
        history.push(e.detail.href);
      },
      ariaLabel: "Breadcrumbs",
      items: [{ text: "Workload Discovery on AWS", href: HOMEPAGE_PATH }].concat(items)
    }
  );
};
Breadcrumbs.propTypes = {
  items: PropTypes.array
};
export default Breadcrumbs;
