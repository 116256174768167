import React from "react";
import {
  Header,
  FormField,
  Container,
  Multiselect,
  ColumnLayout,
  SpaceBetween
} from "@cloudscape-design/components";
import PropTypes from "prop-types";
import CostDatePicker from "../../Diagrams/Draw/Utils/CostDatePicker";
import { useAccounts } from "../../Hooks/useAccounts";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import * as R from "ramda";
dayjs.extend(localizedFormat);
const CostAccountsAndRegionsSelector = ({
  selectedAccounts,
  setSelectedAccounts,
  selectedRegions,
  setSelectedRegions,
  setDateInterval
}) => {
  const { data: accountsData = [] } = useAccounts();
  const accounts = R.map((account) => {
    return {
      label: account.accountId,
      value: account.accountId,
      accountId: account.accountId,
      regions: R.chain((region) => {
        return { value: region.name, label: region.name };
      }, account.regions)
    };
  }, accountsData);
  return /* @__PURE__ */ React.createElement(
    Container,
    {
      header: /* @__PURE__ */ React.createElement(
        Header,
        {
          variant: "h2",
          description: "Specify the Accounts & Regions to query"
        },
        "Accounts & Regions"
      )
    },
    /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "vertical", size: "l" }, /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 3 }, /* @__PURE__ */ React.createElement(
      FormField,
      {
        label: "Account Ids",
        description: "The AWS accounts to include in the query."
      },
      /* @__PURE__ */ React.createElement(
        Multiselect,
        {
          description: "The time period for which cost data is calculated.",
          selectedOptions: selectedAccounts,
          onChange: ({ detail }) => setSelectedAccounts(detail.selectedOptions),
          deselectAriaLabel: (e) => "Remove " + e.label,
          options: accounts,
          placeholder: "All accounts",
          selectedAriaLabel: "Selected"
        }
      )
    ), /* @__PURE__ */ React.createElement(
      FormField,
      {
        label: "Regions",
        description: "The regions to include in the query."
      },
      /* @__PURE__ */ React.createElement(
        Multiselect,
        {
          disabled: R.isEmpty(selectedAccounts),
          selectedOptions: selectedRegions,
          onChange: ({ detail }) => setSelectedRegions(detail.selectedOptions),
          deselectAriaLabel: (e) => "Remove " + e.label,
          options: R.uniq(R.chain((e) => e.regions, selectedAccounts)),
          placeholder: "All regions",
          selectedAriaLabel: "Selected"
        }
      )
    ), /* @__PURE__ */ React.createElement(CostDatePicker, { disabled: false, onIntervalChange: setDateInterval }), " "))
  );
};
CostAccountsAndRegionsSelector.propTypes = {
  selectedAccounts: PropTypes.array.isRequired,
  setSelectedAccounts: PropTypes.func.isRequired,
  selectedRegions: PropTypes.array.isRequired,
  setSelectedRegions: PropTypes.func.isRequired
};
export default CostAccountsAndRegionsSelector;
