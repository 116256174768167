import React from "react";
import {
  Alert,
  Container,
  Header,
  SpaceBetween,
  TextContent
} from "@cloudscape-design/components";
import RegionUploadMethod from "./RegionUploadMethod";
import RegionEditor from "./RegionEditor";
import FileUploader from "../../../Utils/Forms/Upload/AWSFileUpload";
import ImportTable from "./ImportTable";
import { regionMap } from "../../../Utils/Dictionaries/RegionMap";
import * as R from "ramda";
const mapIndexed = R.addIndex(R.map);
const CSV = "csv";
const itemHasAccountId = R.has("accountId");
const itemHasAccountName = R.has("accountName");
const itemHasRegion = R.has("region");
const itemHasAll = R.allPass([
  itemHasAccountId,
  itemHasAccountName,
  itemHasRegion
]);
const csvAccountIdIsValid = (item) => R.equals(R.length(R.prop("accountId", item)), 12);
const csvAccountNameIsValid = (item) => R.lte(R.length(R.prop("accountName", item)), 64);
const csvRegionisValid = (item) => R.gte(R.findIndex(R.propEq("id", R.prop("region", item)), regionMap), 0);
const itemIsValid = R.allPass([
  csvAccountIdIsValid,
  csvAccountNameIsValid,
  csvRegionisValid
]);
const RegionProvider = ({ regions, setRegions }) => {
  const [uploadMethod, setUploadMethod] = React.useState(CSV);
  const [csvError, setCSVError] = React.useState([]);
  const handleChange = (items) => {
    setRegions(R.uniq([...regions].concat(items)));
  };
  const validateAndUploadCSV = (items) => {
    setCSVError([]);
    const tooManyItems = R.gt(R.length(items), 50);
    const invalidItems = R.reduce(
      (acc, val) => {
        if (!itemHasAll(val))
          acc.push({
            item: val,
            message: "A column is missing from the CSV file."
          });
        if (!itemIsValid(val))
          acc.push({
            item: val,
            message: "There are formatting errors in the values provided"
          });
        return acc;
      },
      [],
      items
    );
    if (tooManyItems)
      invalidItems.push({
        item: null,
        message: "Too many Regions provided, you can upload a maximum of 50 Regions"
      });
    R.isEmpty(invalidItems) ? handleChange(items) : setCSVError(R.uniqBy(R.prop("message"), invalidItems));
  };
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(Container, { header: /* @__PURE__ */ React.createElement(Header, { variant: "h2" }, "Import Method") }, /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(RegionUploadMethod, { setUploadMethod }), R.equals(CSV, uploadMethod) ? /* @__PURE__ */ React.createElement(
    FileUploader,
    {
      validateAndUpload: validateAndUploadCSV,
      onError: (item) => setCSVError(item)
    }
  ) : /* @__PURE__ */ React.createElement(RegionEditor, { onChange: handleChange }), !R.isEmpty(csvError) && /* @__PURE__ */ React.createElement(
    Alert,
    {
      visible: true,
      dismissAriaLabel: "Close alert",
      type: "error",
      header: "That CSV file is not valid."
    },
    /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("ul", null, mapIndexed(
      (e, index) => /* @__PURE__ */ React.createElement("li", { key: index }, e.message),
      csvError
    )))
  ))), /* @__PURE__ */ React.createElement(ImportTable, { regions, setRegions }));
};
export default RegionProvider;
