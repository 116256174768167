import React from "react";
import { SpaceBetween } from "@cloudscape-design/components";
import DiscoverableRegionsTable from "./DiscoverableRegionsTable";
import Breadcrumbs from "../../../Utils/Breadcrumbs";
import { ACCOUNTS } from "../../../routes";
import DiscoverableAccountsTable from "./DiscoverableAccountsTable";
import * as R from "ramda";
const DiscoverableAccountsPage = () => {
  const [selectedAccounts, setSelectedAccounts] = React.useState([]);
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(Breadcrumbs, { items: [{ text: "Accounts", href: ACCOUNTS }] }), /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(
    DiscoverableAccountsTable,
    {
      selectedAccounts,
      onSelect: setSelectedAccounts
    }
  ), !R.isEmpty(selectedAccounts) && /* @__PURE__ */ React.createElement(
    DiscoverableRegionsTable,
    {
      selectedAccounts
    }
  )));
};
export default DiscoverableAccountsPage;
