import React from "react";
import {
  TextContent,
  ExpandableSection,
  ColumnLayout,
  SpaceBetween,
  StatusIndicator,
  Grid
} from "@cloudscape-design/components";
import ValueWithLabel from "../../../../../components/Shared/ValueWithLabel";
import * as R from "ramda";
const removeBrackets = (item) => item.replace(/[[\]']+/g, "").replaceAll('"', "");
export const StatementItem = ({ statement }) => {
  const resources = R.split(",", removeBrackets(statement.resources));
  const actions = R.split(",", removeBrackets(statement.actions));
  const warningActions = (action) => action.includes("*");
  const badActions = (action) => action === "*";
  const getEffect = (effect) => R.equals(effect, "Allow") ? "success" : "error";
  const processActions = (actions2) => R.reduce(
    (acc, val) => {
      if (badActions(val))
        acc.push("error");
      if (warningActions(val))
        acc.push("warning");
      return acc;
    },
    [],
    actions2
  );
  const getOverview = (items) => {
    const processedActions = processActions(items);
    if (R.includes("error", processedActions))
      return "error";
    if (R.includes("warning", processedActions))
      return "warning";
    else
      return "success";
  };
  const getDescription = (items) => {
    const processedActions = processActions(items);
    if (R.includes("error", processedActions))
      return "Only wildcards used.";
    if (R.includes("warning", processedActions))
      return "Wildcards used.";
    else
      return "Good";
  };
  const mapIndexed = R.addIndex(R.map);
  return /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 1 }, /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 2, variant: "text-grid" }, /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Effect" }, /* @__PURE__ */ React.createElement(StatusIndicator, { type: getEffect(statement.effect) }, statement.effect))), /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "No. of Resources" }, R.length(resources)), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "No. of Actions" }, R.length(actions)))), /* @__PURE__ */ React.createElement(Grid, { gridDefinition: [{ colspan: 6 }, { colspan: 6 }] }, /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Actions health" }, /* @__PURE__ */ React.createElement(StatusIndicator, { type: getOverview(actions) }, getDescription(actions))), /* @__PURE__ */ React.createElement(ExpandableSection, { header: "Actions" }, /* @__PURE__ */ React.createElement("ul", null, mapIndexed(
    (e, index) => /* @__PURE__ */ React.createElement(TextContent, { key: index }, /* @__PURE__ */ React.createElement("li", { key: index }, e)),
    actions
  )))), /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Resources health" }, /* @__PURE__ */ React.createElement(StatusIndicator, { type: getOverview(resources) }, getDescription(resources))), /* @__PURE__ */ React.createElement(ExpandableSection, { header: "Resources" }, /* @__PURE__ */ React.createElement("ul", null, mapIndexed(
    (e, index) => /* @__PURE__ */ React.createElement(TextContent, { key: index }, /* @__PURE__ */ React.createElement("li", { key: index }, e)),
    resources
  ))))));
};
export default StatementItem;
