import React from "react";
import {
  Box,
  Container,
  ColumnLayout,
  TextContent
} from "@cloudscape-design/components";
import PropTypes from "prop-types";
const SummaryOverview = ({ cost, from, to, resultCount }) => {
  return /* @__PURE__ */ React.createElement(Container, { "data-testid": "costs-summary-overview" }, /* @__PURE__ */ React.createElement(ColumnLayout, { disableGutters: true, columns: "4", variant: "text-grid" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Box, { margin: { bottom: "xxxs" }, color: "text-label" }, "Estimated AWS cost"), /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, cost))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Box, { margin: { bottom: "xxxs" }, color: "text-label" }, "AWS Resources"), /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, resultCount))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Box, { margin: { bottom: "xxxs" }, color: "text-label" }, "From"), /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, from))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Box, { margin: { bottom: "xxxs" }, color: "text-label" }, "To"), /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, to)))));
};
SummaryOverview.propTypes = {
  cost: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  resultCount: PropTypes.number.isRequired
};
export default SummaryOverview;
