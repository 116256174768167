import React from "react";
import { fetchImage } from "../../../../Utils/ImageSelector";
import CloudFrontDistributionItem from "./CloudFrontDistributionDetails/CloudFrontDistributionItem";
import * as R from "ramda";
export const parseCloudFrontDistribution = (node) => {
  const properties = R.hasPath(["properties"], node) ? node.properties : node.data("properties");
  return {
    styling: {
      borderStyle: "solid",
      borderColour: "#545B64",
      borderOpacity: 0.25,
      borderSize: 1,
      message: "",
      colour: "#fff"
    },
    icon: fetchImage(properties.resourceType, void 0),
    detailsComponent: /* @__PURE__ */ React.createElement(
      CloudFrontDistributionItem,
      {
        title: "Distribution Details",
        configuration: properties.configuration
      }
    )
  };
};
