import React, { useEffect } from "react";
import * as R from "ramda";
import {
  ColumnLayout,
  SpaceBetween
} from "@cloudscape-design/components";
import Breadcrumbs from "../../../Utils/Breadcrumbs";
import ViewExplorerAccountsTable from "./ViewExplorerAccountsTable";
import ViewExplorerRegionsTable from "./ViewExplorerRegionsTable";
import ViewExplorerResourceTypesTable from "./ViewExplorerResourceTypesTable";
import ViewSelector from "./Shared/ViewSelector";
import ViewOverview from "./Shared/ViewOverview";
import { useDiagramSettingsState } from "../../Contexts/DiagramSettingsContext";
import { useResourceState } from "../../Contexts/ResourceContext";
import { VIEWS } from "../../../routes";
import ResourcesTable from "../Shared/ResourcesTable";
const filterAccounts = R.chain((e) => {
  return {
    accountId: e.accountId,
    ...e.regions?.length > 0 ? {
      regions: R.map((r) => {
        return { name: r.name };
      }, R.pathOr([], ["regions"], e))
    } : {}
  };
});
const ViewExplorerPage = () => {
  const [selectedView, setSelectedView] = React.useState(null);
  const [, dispatchCanvas] = useDiagramSettingsState();
  const [, dispatch] = useResourceState();
  useEffect(() => {
    dispatch({
      type: "select",
      resources: {}
    });
    dispatchCanvas({
      type: "setCanvas",
      canvas: null
    });
    dispatchCanvas({
      type: "setResources",
      resources: []
    });
  }, [dispatch, dispatchCanvas]);
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(Breadcrumbs, { items: [{ text: "Views", href: VIEWS }] }), /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 1 }, /* @__PURE__ */ React.createElement(ViewSelector, { selectedOption: selectedView?.key, onSelect: setSelectedView }), selectedView && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    ViewOverview,
    {
      accounts: R.pathOr([], ["accounts"], selectedView),
      regions: R.pathOr([], ["regions"], selectedView),
      resourceTypes: R.pathOr([], ["resourceTypes"], selectedView)
    }
  ), /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 3 }, /* @__PURE__ */ React.createElement(ViewExplorerAccountsTable, { selectedView }), /* @__PURE__ */ React.createElement(ViewExplorerRegionsTable, { selectedView }), /* @__PURE__ */ React.createElement(ViewExplorerResourceTypesTable, { selectedView })), /* @__PURE__ */ React.createElement(
    ResourcesTable,
    {
      accounts: filterAccounts(R.pathOr([], ["accounts"], selectedView)),
      resourceTypes: R.map(
        (r) => r.type,
        R.pathOr([], ["resourceTypes"], selectedView)
      ),
      pageSize: 10
    }
  ))));
};
export default ViewExplorerPage;
