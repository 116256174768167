import React from "react";
import * as R from "ramda";
import dayjs from "dayjs";
import { hashProperty } from "../../../Utils/ObjectUtils";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  Table,
  Box,
  TextFilter,
  Header,
  Pagination,
  Button,
  SpaceBetween,
  StatusIndicator,
  Modal
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { isEmpty } from "ramda";
import PropTypes from "prop-types";
import { useAccounts, useRemoveAccountRegion } from "../../Hooks/useAccounts";
import { useResourcesRegionMetadata } from "../../Hooks/useResourcesMetadata";
import { isUsingOrganizations } from "../../../Utils/AccountUtils";
import { createTableAriaLabels } from "../../../Utils/AccessibilityUtils";
import useQueryErrorHandler from "../../Hooks/useQueryErrorHandler";
dayjs.extend(relativeTime);
const columns = [
  {
    id: "id",
    cell: (e) => `${e.accountId}-${e.name}`
  },
  {
    id: "region",
    header: "Region",
    cell: (e) => e.name,
    width: 200,
    minWidth: 200
  },
  {
    id: "account",
    header: "Account Id",
    cell: (e) => e.accountId,
    width: 200,
    minWidth: 200
  },
  {
    id: "count",
    header: "Resources",
    cell: (e) => e.count ? e.count : /* @__PURE__ */ React.createElement(StatusIndicator, { type: "warning" }, "Not discovered"),
    width: 150,
    minWidth: 150
  }
];
const DiscoverableRegionsTable = ({ selectedAccounts }) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const { data: accounts = [], isLoading: loadingAccounts } = useAccounts();
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  const { removeAsync } = useRemoveAccountRegion();
  const { handleError } = useQueryErrorHandler();
  const { data = [], isLoading: loadingRegions } = useResourcesRegionMetadata(R.chain((e) => {
    return {
      accountId: e.accountId,
      regions: R.map((r) => {
        return { name: r.name };
      }, e.regions)
    };
  }, selectedAccounts));
  const addCount = (region, response) => {
    const x = R.find(R.propEq("id", region.id))(response);
    return x ? R.assoc("count", x.count, region) : region;
  };
  const accountsToRegions = R.compose(
    R.map((e) => R.assoc("id", `${e.accountId}-${e.name}`, e)),
    R.chain((e) => R.map(R.assoc("accountId", e.accountId), e.regions))
  );
  const regions = R.compose(
    R.uniqBy((e) => e.id),
    R.map((e) => addCount(e, accountsToRegions(data))),
    R.reduce(
      (acc, e) => R.concat(
        acc,
        R.chain((region) => {
          return {
            accountId: e.accountId,
            accountName: e.name,
            name: region.name,
            id: `${e.accountId}-${region.name}`
          };
        }, e.regions)
      ),
      []
    ),
    R.filter((x) => !R.isNil(R.find(R.propEq("accountId", x.accountId))(selectedAccounts)))
  )(accounts);
  const {
    items,
    filterProps,
    collectionProps,
    paginationProps
  } = useCollection(regions, {
    filtering: {
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No Regions"), /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No Region matched filter")),
      noMatch: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No Region"), /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No Region matched filter"))
    },
    pagination: { pageSize: 10 },
    sorting: {}
  });
  const handleDelete = () => {
    removeAsync({
      accountId: R.head(selectedAccounts).accountId,
      regions: R.map((e) => {
        return { name: e.name };
      }, selectedRegions)
    }).catch(handleError).then(() => setShowDeleteConfirm(false)).then(() => setSelectedRegions([]));
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Modal,
    {
      onDismiss: () => setShowDeleteConfirm(false),
      visible: showDeleteConfirm,
      closeAriaLabel: "Close modal",
      footer: /* @__PURE__ */ React.createElement(Box, { float: "right" }, /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "horizontal", size: "xs" }, /* @__PURE__ */ React.createElement(Button, { onClick: () => setShowDeleteConfirm(false), variant: "link" }, "Cancel"), /* @__PURE__ */ React.createElement(Button, { onClick: handleDelete, variant: "primary" }, "Delete"))),
      header: "Remove Region"
    },
    /* @__PURE__ */ React.createElement(Box, null, "Remove the following regions for ", /* @__PURE__ */ React.createElement("strong", null, selectedAccounts.map((i) => i.accountId).join(", ")), "?"),
    /* @__PURE__ */ React.createElement("ul", null, selectedRegions.map((i) => /* @__PURE__ */ React.createElement("li", { key: hashProperty(i.name) }, /* @__PURE__ */ React.createElement("strong", null, i.name))))
  ), /* @__PURE__ */ React.createElement(
    Table,
    {
      ariaLabels: createTableAriaLabels("region", "regions", {
        keys: ["region"],
        fallback: "Unknown region"
      }, "Regions"),
      ...collectionProps,
      header: /* @__PURE__ */ React.createElement(
        Header,
        {
          variant: "h2",
          description: "AWS Regions that have been imported into Workload Discovery on AWS.",
          actions: isUsingOrganizations() ? void 0 : /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "horizontal", size: "l" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              loadingText: "Removing",
              variant: "primary",
              disabled: isEmpty(selectedRegions),
              onClick: () => setShowDeleteConfirm(true)
            },
            "Remove"
          ))
        },
        "Regions"
      ),
      trackBy: "id",
      loading: loadingAccounts || loadingRegions,
      resizableColumns: true,
      columnDefinitions: columns,
      visibleColumns: ["region", "name", "account", "count", "types"],
      items,
      selectedItems: selectedRegions,
      selectionType: "multi",
      onSelectionChange: (evt) => setSelectedRegions(evt.detail.selectedItems),
      isItemDisabled: isUsingOrganizations,
      loadingText: "Loading Regions",
      filter: /* @__PURE__ */ React.createElement(TextFilter, { ...filterProps, filteringPlaceholder: "Find a Region..." }),
      pagination: /* @__PURE__ */ React.createElement(Pagination, { ...paginationProps })
    }
  ));
};
DiscoverableRegionsTable.propTypes = {
  selectedAccounts: PropTypes.array.isRequired
};
export default DiscoverableRegionsTable;
