const icons = /* @__PURE__ */ new Map(
  [
    ["AWS::ACM::Certificate", "Res_AWS-Certificate-Manager_Certificate_48_Light"],
    ["AWS::AccessAnalyzer::Analyzer", "Res_AWS-Identity-Access-Management_AWS-IAM-Access-Analyzer_48_Light"],
    ["AWS::AmazonMQ::Broker", "Res_Amazon-MQ_Broker_48"],
    ["AWS::Amplify::App", "Arch_AWS-Amplify_64-App"],
    ["AWS::ApiGateway::Authorizer", "API-Gateway-Authorizer"],
    ["AWS::ApiGateway::Method", "API-Gateway-method-Other_light-bg"],
    ["AWS::ApiGateway::Resource", "API-Gateway-Resource-light-bg"],
    ["AWS::ApiGateway::RestApi", "Amazon-API-Gateway_Endpoint_light-bg"],
    ["AWS::ApiGateway::Stage", "API-Gateway-Stage"],
    ["AWS::ApiGatewayV2::Api", "Res_Amazon-API-Gateway_Endpoint_48_Light"],
    ["AWS::ApiGatewayV2::Stage", "API-GatewayV2-Stage"],
    ["AWS::AppConfig::ConfigurationProfile", "Arch_AWS-AppConfig_64-ConfigurationProfile"],
    ["AWS::AppConfig::DeploymentStrategy", "Arch_AWS-AppConfig_64-DeploymentStrategy"],
    ["AWS::AppConfig::Environment", "Arch_AWS-AppConfig_64-Environment"],
    ["AWS::AppMesh::Mesh", "Res_AWS-App-Mesh_Mesh_48"],
    ["AWS::AppMesh::VirtualGateway", "Res_AWS-App-Mesh_Virtual-Gateway_48"],
    ["AWS::AppMesh::VirtualNode", "Res_AWS-App-Mesh_Virtual-Node_48"],
    ["AWS::AppMesh::VirtualRouter", "Res_AWS-App-Mesh_Virtual-Router_48"],
    ["AWS::AppMesh::VirtualService", "Res_AWS-App-Mesh_Virtual-Service_48"],
    ["AWS::AppRunner::Service", "Arch_AWS-App-Runner_64-Service"],
    ["AWS::AppRunner::VpcConnector", "Arch_AWS-App-Runner_64-VpcConnector"],
    ["AWS::AppStream::Application", "Arch_Amazon-AppStream_64-Application"],
    ["AWS::AppStream::DirectoryConfig", "Arch_Amazon-AppStream_64-DirectoryConfig"],
    ["AWS::AppStream::Stack", "Arch_Amazon-AppStream_64-Stack"],
    ["AWS::AppSync::GraphQLApi", "Arch_AWS-AppSync_64-GraphQLApi"],
    ["AWS::AutoScaling::AutoScalingGroup", "Amazon-EC2-Auto-Scaling"],
    ["AWS::AutoScaling::LaunchConfiguration", "AWS-AutoScaling-LaunchConfiguration"],
    ["AWS::AutoScaling::ScalingPolicy", "AWS-AutoScaling-ScalingPolicy"],
    ["AWS::AutoScaling::WarmPool", "Arch_AWS-Auto-Scaling_64-WarmPool"],
    ["AWS::Backup::BackupPlan", "Res_AWS-Backup_Backup-Plan_48_Light"],
    ["AWS::Backup::BackupSelection", "AWS-Backup-Selection"],
    ["AWS::Backup::BackupVault", "Res_AWS-Backup_Backup-Vault_48_Light"],
    ["AWS::Backup::RecoveryPoint", "Res_AWS-Backup_Recovery-Point-Objective_48_Light"],
    ["AWS::Batch::ComputeEnvironment", "Res_AWS-Batch_Compute_48_Light"],
    ["AWS::Batch::JobQueue", "Arch_AWS-Batch_Queue_64"],
    ["AWS::Budgets::BudgetsAction", "Arch_AWS-Budgets_64-BudgetsAction"],
    ["AWS::Cassandra::Keyspace", "Arch_Amazon-Keyspaces_64"],
    ["AWS::Cloud9::EnvironmentEC2", "Res_AWS-Cloud9_Cloud9_48-EnvironmentEC2"],
    ["AWS::CloudFormation::Stack", "AWS-CloudFormation"],
    ["AWS::CloudFront::Distribution", "Amazon-CloudFront_light-bg"],
    ["AWS::CloudFront::StreamingDistribution", "Amazon-CloudFront_Streaming-Distribution_light-bg"],
    ["AWS::CloudTrail::Trail", "Amazon-CloudTrail-trail-light-bg"],
    ["AWS::CloudWatch::Alarm", "Amazon-CloudWatch_Alarm_light-bg"],
    ["AWS::CodeArtifact::Repository", "Arch_AWS-CodeArtifact_64-Repository"],
    ["AWS::CodeBuild::Project", "AWS-CodeBuild_light-bg"],
    ["AWS::CodeDeploy::Application", "Arch_AWS-CodeDeploy_64-Application"],
    ["AWS::CodeDeploy::DeploymentConfig", "Arch_AWS-CodeDeploy_64-DeploymentConfig"],
    ["AWS::CodeDeploy::DeploymentGroup", "Arch_AWS-CodeDeploy_64-DeploymentGroup"],
    ["AWS::CodeGuruReviewer::ProfilingGroup", "Arch_Amazon-CodeGuruReviewer_64-ProfilingGroup"],
    ["AWS::CodeGuruReviewer::RepositoryAssociation", "Arch_Amazon-CodeGuruReviewer_64-RepositoryAssociation"],
    ["AWS::CodePipeline::Pipeline", "AWS-CodePipeline_light-bg"],
    ["AWS::Cognito::UserPool", "Arch_Amazon-Cognito_64"],
    ["AWS::Config::ConfigurationRecorder", "Arch_AWS-Config_64-ConfigurationRecorder"],
    ["AWS::Config::ConformancePackCompliance", "Arch_AWS-Config_64-ConformancePackCompliance"],
    ["AWS::Config::ResourceCompliance", "Arch_AWS-Config_64"],
    ["AWS::Connect::PhoneNumber", "Arch_Amazon-Connect_64-PhoneNumber"],
    ["AWS::CustomerProfiles::Domain", "Arch_Amazon-Connect_64-Domain"],
    ["AWS::DMS::Certificate", "Arch_AWS-Database-Migration-Service_64-Certificate"],
    ["AWS::DMS::EventSubscription", "Res_Amazon-Simple-Notification-Service_DMS_Notification_48_Light"],
    ["AWS::DMS::ReplicationInstance", "Arch_AWS-Database-Migration-Service_64-ReplicationInstance"],
    ["AWS::DMS::ReplicationSubnetGroup", "DMSReplicationSubnetGroup"],
    ["AWS::DMS::ReplicationTask", "Arch_AWS-Database-Migration-Service_64-ReplicationTask"],
    ["AWS::DeviceFarm::TestGridProject", "Arch_AWS-Device-Farm_64-TestGridProject"],
    ["AWS::DynamoDB::Stream", "Res_Amazon-DynamoDB_Stream_48"],
    ["AWS::DynamoDB::Table", "Amazon-DynamoDB_Table_light-bg"],
    ["AWS::EC2::CustomerGateway", "Amazon-VPC_Customer_Gateway_light-bg-menu"],
    ["AWS::EC2::CustomerGateway-status-available", "Amazon-VPC_Customer_Gateway_light-bg"],
    ["AWS::EC2::CustomerGateway-status-negative", "Amazon-VPC_Customer_Gateway_light-bg-error"],
    ["AWS::EC2::CustomerGateway-status-warning", "Amazon-VPC_Customer_Gateway_light-bg-warning"],
    ["AWS::EC2::DHCPOptions", "Res_Amazon-EC2_Instances_48-DHCPOptions"],
    ["AWS::EC2::EIP", "Amazon-EC2_Elastic-IP-Address_light-bg"],
    ["AWS::EC2::FlowLog", "Amazon-VPC_Flow-Logs_light-bg"],
    ["AWS::EC2::IPAM", "Res_Amazon-EC2_Instances_48-IPAM"],
    ["AWS::EC2::Instance", "Amazon-EC2_Instance_light-bg"],
    ["AWS::EC2::InternetGateway", "Amazon-VPC_Internet-Gateway_light-bg"],
    ["AWS::EC2::LaunchTemplate", "Res_AWS-EC2_Launch_Template_48_Light"],
    ["AWS::EC2::NatGateway", "Amazon-VPC_NAT-Gateway_light-bg-menu"],
    ["AWS::EC2::NatGateway-status-available", "Amazon-VPC_NAT-Gateway_light-bg"],
    ["AWS::EC2::NatGateway-status-negative", "Amazon-VPC_NAT-Gateway_light-bg-error"],
    ["AWS::EC2::NatGateway-status-warning", "Amazon-VPC_NAT-Gateway_light-bg-warning"],
    ["AWS::EC2::NetworkAcl", "Amazon-VPC_Network-Access-Control-List_light-bg"],
    ["AWS::EC2::NetworkInsightsPath", "Res_Amazon-EC2_Instances_48-NetworkInsightsPath"],
    ["AWS::EC2::NetworkInterface", "Amazon-VPC_Elastic-Network-Interface_light-bg"],
    ["AWS::EC2::PrefixList", "Res_Amazon-EC2_Instance_48-PrefixList"],
    ["AWS::EC2::RouteTable", "Amazon-VPC_Router_light-bg"],
    ["AWS::EC2::SecurityGroup", "AWSSecurityGroup"],
    ["AWS::EC2::Spot", "Amazon-EC2_Spot-Instance_light-bg-menu"],
    ["AWS::EC2::Spot-status-available", "Amazon-EC2_Spot-Instance_light-bg"],
    ["AWS::EC2::Spot-status-negative", "Amazon-EC2_Spot-Instance_light-bg-error"],
    ["AWS::EC2::Spot-status-warning", "Amazon-EC2_Spot-Instance_light-bg-warning"],
    ["AWS::EC2::SpotFleet", "Res_Amazon-EC2_Spot-Instance_48-SpotFleet"],
    ["AWS::EC2::Subnet", "VPC-subnet-private_light-bg-menu"],
    ["AWS::EC2::Subnet-public", "VPC-subnet-public_light-bg-menu"],
    ["AWS::EC2::Subnet-public-status-available", "VPC-subnet-public_light-bg"],
    ["AWS::EC2::Subnet-public-status-negative", "VPC-subnet-public_light-bg-error"],
    ["AWS::EC2::Subnet-public-status-warning", "VPC-subnet-public_light-bg-warning"],
    ["AWS::EC2::Subnet-status-available", "VPC-subnet-private_light-bg"],
    ["AWS::EC2::Subnet-status-negative", "VPC-subnet-private_light-bg-error"],
    ["AWS::EC2::Subnet-status-warning", "VPC-subnet-private_light-bg-warning"],
    ["AWS::EC2::SubnetRouteTableAssociation", "Arch_Amazon-EC2_64-SubnetRouteTableAssociation"],
    ["AWS::EC2::TrafficMirrorFilter", "Res_Amazon-EC2_Instances_48-TrafficMirrorFilter"],
    ["AWS::EC2::TrafficMirrorSession", "Res_Amazon-EC2_Instances_48-TrafficMirrorSession"],
    ["AWS::EC2::TrafficMirrorTarget", "Res_Amazon-EC2_Instances_48-TrafficMirrorTarget"],
    ["AWS::EC2::TransitGateway", "Arch_AWS-Transit-Gateway_64"],
    ["AWS::EC2::TransitGatewayAttachment", "Res_AWS-Transit-Gateway_Attachment_48"],
    ["AWS::EC2::TransitGatewayRouteTable", "Res_AWS-Cloud-WAN_Transit-Gateway-Route-Table-Attachment_48"],
    ["AWS::EC2::VPC", "VPC-menu"],
    ["AWS::EC2::VPC-status-available", "VPC"],
    ["AWS::EC2::VPC-status-negative", "VPC-error"],
    ["AWS::EC2::VPC-status-warning", "VPC-warning"],
    ["AWS::EC2::VPCEndpoint", "Amazon-EC2_VPC_Endpoints_light-bg-menu"],
    ["AWS::EC2::VPCEndpoint-status-available", "Amazon-EC2_VPC_Endpoints_light-bg"],
    ["AWS::EC2::VPCEndpoint-status-negative", "Amazon-EC2_VPC_Endpoints_light-bg-error"],
    ["AWS::EC2::VPCEndpoint-status-warning", "Amazon-EC2_VPC_Endpoints_light-bg-warning"],
    ["AWS::EC2::VPNConnection", "Amazon-VPN_Connection_light-bg-menu"],
    ["AWS::EC2::VPNConnection-status-available", "Amazon-VPN_Connection_light-bg"],
    ["AWS::EC2::VPNConnection-status-negative", "Amazon-VPN_Connection_light-bg-error"],
    ["AWS::EC2::VPNConnection-status-warning", "Amazon-VPN_Connection_light-bg-warning"],
    ["AWS::EC2::VPNConnectionRoute", "Amazon-VPN_Connection_Route_light-bg-menu"],
    ["AWS::EC2::VPNConnectionRoute-status-available", "Amazon-VPN_Connection_Route_light-bg"],
    ["AWS::EC2::VPNConnectionRoute-status-negative", "Amazon-VPN_Connection_Route_light-bg-error"],
    ["AWS::EC2::VPNConnectionRoute-status-warning", "Amazon-VPN_Connection_Route_light-bg-warning"],
    ["AWS::EC2::VPNGateway", "Amazon-VPN_Gateway_light-bg-menu"],
    ["AWS::EC2::VPNGateway-status-available", "Amazon-VPN_Gateway_light-bg"],
    ["AWS::EC2::VPNGateway-status-negative", "Amazon-VPN_Gateway_light-bg-error"],
    ["AWS::EC2::VPNGateway-status-warning", "Amazon-VPN_Gateway_light-bg-warning"],
    ["AWS::EC2::Volume", "Amazon-Elastic-Block-Store-EBS_Volume_light-bg-menu"],
    ["AWS::EC2::Volume-status-available", "Amazon-Elastic-Block-Store-EBS_Volume_light-bg"],
    ["AWS::EC2::Volume-status-negative", "Amazon-Elastic-Block-Store-EBS_Volume_light-bg-error"],
    ["AWS::EC2::Volume-status-warning", "Amazon-Elastic-Block-Store-EBS_Volume_light-bg-warning"],
    ["AWS::ECR::Repository", "Amazon-EC2-Container-Registry"],
    ["AWS::ECS::Cluster", "Amazon-Elastic-Container-Service-menu"],
    ["AWS::ECS::Cluster-status-available", "Amazon-Elastic-Container-Service"],
    ["AWS::ECS::Cluster-status-negative", "Amazon-Elastic-Container-Service-error"],
    ["AWS::ECS::Cluster-status-warning", "Amazon-Elastic-Container-Service-warning"],
    ["AWS::ECS::EnvironmentVariable", "Env-variable-ECS-light-bg"],
    ["AWS::ECS::Service", "Amazon-Elastic-Container-Service_Service_light-bg-menu"],
    ["AWS::ECS::Service-status-available", "Amazon-Elastic-Container-Service_Service_light-bg"],
    ["AWS::ECS::Service-status-error", "Amazon-Elastic-Container-Service_Service_light-bg-error"],
    ["AWS::ECS::Service-status-warning", "Amazon-Elastic-Container-Service_Service_light-bg-warning"],
    ["AWS::ECS::Task", "Amazon-Elastic-Container-Service_Task_light-bg-menu"],
    ["AWS::ECS::Task-status-available", "Amazon-Elastic-Container-Service_Task_light-bg"],
    ["AWS::ECS::Task-status-error", "Amazon-Elastic-Container-Service_Task_light-bg-error"],
    ["AWS::ECS::Task-status-warning", "Amazon-Elastic-Container-Service_Task_light-bg-warning"],
    ["AWS::ECS::TaskDefinition", "ECS-Task-Definition-light-bg-menu"],
    ["AWS::ECS::TaskDefinition-status-available", "ECS-Task-Definition-light-bg"],
    ["AWS::ECS::TaskDefinition-status-error", "ECS-Task-Definition-light-bg-error"],
    ["AWS::ECS::TaskDefinition-status-warning", "ECS-Task-Definition-light-bg-warning"],
    ["AWS::ECS::TaskSet", "Res_Amazon-Elastic-Container-Service_Task_48-TaskSet"],
    ["AWS::EFS::AccessPoint", "Res_Amazon-Simple-Storage-Service_General-Access-Points_48_Light"],
    ["AWS::EFS::FileSystem", "Res_Amazon-Elastic-File-System_File-System_48_Light"],
    ["AWS::EKS::Addon", "Arch_Amazon-Elastic-Kubernetes-Service_64-Addon"],
    ["AWS::EKS::Cluster", "Amazon-Elastic-Kubernetes-Service-menu"],
    ["AWS::EKS::Cluster-status-available", "Amazon-Elastic-Kubernetes-Service"],
    ["AWS::EKS::Cluster-status-negative", "Amazon-Elastic-Kubernetes-Service-error"],
    ["AWS::EKS::Cluster-status-warning", "Amazon-Elastic-Kubernetes-Service-warning"],
    ["AWS::EKS::FargateProfile", "Arch_Amazon-EKS-Distro_64-FargateProfile"],
    ["AWS::EKS::IdentityProviderConfig", "Arch_Amazon-Elastic-Kubernetes-Service_64-IdentityProviderConfig"],
    ["AWS::EKS::Nodegroup", "Arch_Amazon-EKS-Distro_64"],
    ["AWS::ElasticBeanstalk::Application", "Res_AWS-Elastic-Beanstalk_Deployment_48-ApplicationVersion"],
    ["AWS::ElasticBeanstalk::ApplicationVersion", "Res_AWS-Elastic-Beanstalk_Application_48"],
    ["AWS::ElasticBeanstalk::Environment", "Res_AWS-Elastic-Beanstalk_Deployment_48-Environment"],
    ["AWS::ElasticLoadBalancing::LoadBalancer", "Elastic-Load-Balancing_Classic-load-balancer_light-bg-menu"],
    ["AWS::ElasticLoadBalancing::LoadBalancer-status-available", "Elastic-Load-Balancing_Classic-load-balancer_light-bg"],
    ["AWS::ElasticLoadBalancing::LoadBalancer-status-negative", "Elastic-Load-Balancing_Classic-load-balancer_light-bg-error"],
    ["AWS::ElasticLoadBalancing::LoadBalancer-status-warning", "Elastic-Load-Balancing_Classic-load-balancer_light-bg-warning"],
    ["AWS::ElasticLoadBalancingV2::Listener", "ELB-Listener-light-bg"],
    ["AWS::ElasticLoadBalancingV2::LoadBalancer", "Elastic-Load-Balancing-ELB_Application-load-balancer_light-bg-menu"],
    ["AWS::ElasticLoadBalancingV2::LoadBalancer-application-status-available", "Elastic-Load-Balancing-ELB_Application-load-balancer_light-bg"],
    ["AWS::ElasticLoadBalancingV2::LoadBalancer-application-status-negative", "Elastic-Load-Balancing-ELB_Application-load-balancer_light-bg-error"],
    ["AWS::ElasticLoadBalancingV2::LoadBalancer-application-status-warning", "Elastic-Load-Balancing-ELB_Application-load-balancer_light-bg-warning"],
    ["AWS::ElasticLoadBalancingV2::LoadBalancer-network", "Elastic-Load-Balancing-ELB_Network-load-balancer_light-bg-menu"],
    ["AWS::ElasticLoadBalancingV2::LoadBalancer-network-status-available", "Elastic-Load-Balancing-ELB_Network-load-balancer_light-bg"],
    ["AWS::ElasticLoadBalancingV2::LoadBalancer-network-status-negative", "Elastic-Load-Balancing-ELB_Network-load-balancer_light-bg-error"],
    ["AWS::ElasticLoadBalancingV2::LoadBalancer-network-status-warning", "Elastic-Load-Balancing-ELB_Network-load-balancer_light-bg-warning"],
    ["AWS::ElasticLoadBalancingV2::Target", "ELB-Target-light-bg"],
    ["AWS::ElasticLoadBalancingV2::TargetGroup", "ELB-Target-group-light-bg"],
    ["AWS::Elasticsearch::Domain", "Amazon-Elasticsearch-Service_light-bg"],
    ["AWS::EventSchemas::Discoverer", "Res_Amazon-EventBridge_Rule_48-Discoverer"],
    ["AWS::EventSchemas::Registry", "Res_Amazon-EventBridge_Schema-Registry_48"],
    ["AWS::EventSchemas::RegistryPolicy", "Res_Amazon-EventBridge_Schema-Registry_48-RegistryPolicy"],
    ["AWS::EventSchemas::Schema", "Res_Amazon-EventBridge_Schema_48"],
    ["AWS::Events::Connection", "Res_Amazon-EventBridge_Pipes_48-Connection"],
    ["AWS::Events::EventBus", "Res_Amazon-EventBridge_Default-Event-Bus_48"],
    ["AWS::Events::Rule", "Res_Amazon-EventBridge-Event_48-Rule"],
    ["AWS::Evidently::Project", "Res_Amazon-CloudWatch_Evidently_48"],
    ["AWS::Forecast::Dataset", "Arch_Amazon-Forecast_64-Dataset"],
    ["AWS::FraudDetector::EntityType", "Arch_Amazon-Fraud-Detector_64-EntityType"],
    ["AWS::FraudDetector::Label", "Arch_Amazon-Fraud-Detector_64-Label"],
    ["AWS::FraudDetector::Outcome", "Arch_Amazon-Fraud-Detector_64-Outcome"],
    ["AWS::FraudDetector::Variable", "Arch_Amazon-Fraud-Detector_64-Variable"],
    ["AWS::Glue::Job", "Arch_AWS-Glue_64-Job"],
    ["AWS::Glue::MLTransform", "Res_AWS-Glue_AWS-Glue-for-Ray_48-MLTransform"],
    ["AWS::GuardDuty::Detector", "Arch_Amazon-GuardDuty_64-Detector"],
    ["AWS::HealthLake::FHIRDatastore", "Arch_Amazon-HealthLake_64-FHIRDatastore"],
    ["AWS::IAM::AWSManagedPolicy", "AWS-Identity-and-Access-Management-IAM_Permissions_AWSManagedPolicy-light-bg"],
    ["AWS::IAM::AWSPolicyStatement", "AWS-Identity-and-Access-Management-IAM_Permissions_AWSPolicyStatement-light-bg"],
    ["AWS::IAM::CustomerManagedPolicyStatement", "AWS-Identity-and-Access-Management-IAM_Permissions_CustomerManagedPolicy-light-bg-menu"],
    ["AWS::IAM::CustomerManagedPolicyStatement-status-available", "AWS-Identity-and-Access-Management-IAM_Permissions_CustomerManagedPolicy-light-bg-good"],
    ["AWS::IAM::CustomerManagedPolicyStatement-status-negative", "AWS-Identity-and-Access-Management-IAM_Permissions_CustomerManagedPolicy-light-bg-error"],
    ["AWS::IAM::CustomerManagedPolicyStatement-status-warning", "AWS-Identity-and-Access-Management-IAM_Permissions_CustomerManagedPolicy-light-bg-warning"],
    ["AWS::IAM::Group", "Users_light-bg"],
    ["AWS::IAM::InlinePolicy", "Res_AWS-Identity-Access-Management_Permissions_48_Light"],
    ["AWS::IAM::Policy", "IAM_Policy"],
    ["AWS::IAM::Role", "AWS-Identity-and-Access-Management-IAM_Role_light-bg"],
    ["AWS::IAM::SAMLProvider", "Res_AWS-Identity-Access-Management_AWS-STS_48-SAMLProvider"],
    ["AWS::IAM::ServerCertificate", "Res_AWS-Certificate-Manager_Certificate-Authority_48-ServerCertificate"],
    ["AWS::IAM::User", "User_light-bg"],
    ["AWS::IVS::Channel", "Arch_Amazon-Interactive-Video-Service_64-Channel"],
    ["AWS::IVS::PlaybackKeyPair", "Arch_Amazon-Interactive-Video-Service_64-PlaybackKeyPair"],
    ["AWS::IVS::RecordingConfiguration", "Arch_Amazon-Interactive-Video-Service_64-RecordingConfiguration"],
    ["AWS::ImageBuilder::ContainerRecipe", "Arch_Amazon-EC2-Image-Builder_64-ContainerRecipe"],
    ["AWS::ImageBuilder::DistributionConfiguration", "Arch_Amazon-EC2-Image-Builder_64-DistributionConfiguration"],
    ["AWS::ImageBuilder::InfrastructureConfiguration", "Arch_Amazon-EC2-Image-Builder_64-InfrastructureConfiguration"],
    ["AWS::IoT::AccountAuditConfiguration", "Res_AWS-IoT-Greengrass_Component-Public_48-AuditConfiguration"],
    ["AWS::IoT::Authorizer", "Res_AWS-IoT_Certificate_48-Authorizer"],
    ["AWS::IoT::CustomMetric", "Res_AWS-IoT-Rule_48-CustomMetric"],
    ["AWS::IoT::Dimension", "Res_AWS-IoT_Dimension_48"],
    ["AWS::IoT::MitigationAction", "Res_AWS-IoT_Action_48-MitigationAction"],
    ["AWS::IoT::Policy", "Res_AWS-IoT_Policy_48"],
    ["AWS::IoT::RoleAlias", "Res_AWS-IoT_Policy_48-RoleAlias"],
    ["AWS::IoT::ScheduledAudit", "Res_AWS-IoT-Greengrass_Component-Public_48-ScheduledAudit"],
    ["AWS::IoT::SecurityProfile", "Res_AWS-IoT_Thing_Police-Emergency_48-SecurityProfile"],
    ["AWS::IoTAnalytics::Channel", "Res_AWS-IoT-Analytics_Channel_48"],
    ["AWS::IoTAnalytics::Dataset", "Res_AWS-IoT-Analytics_Dataset_48"],
    ["AWS::IoTAnalytics::Datastore", "Res_AWS-IoT-Analytics_Data-Store_48"],
    ["AWS::IoTAnalytics::Pipeline", "Res_AWS-IoT-Analytics_Pipeline_48"],
    ["AWS::IoTSiteWise::AssetModel", "Res_AWS-IoT-SiteWise_Asset-Model_48"],
    ["AWS::IoTSiteWise::Dashboard", "Res_AWS-IoT-SiteWise_Asset_48-Dashboard"],
    ["AWS::IoTSiteWise::Gateway", "Res_AWS-IoT-SiteWise_Asset-Model_48-Gateway"],
    ["AWS::IoTSiteWise::Portal", "Res_AWS-IoT-SiteWise_Asset_48-Portal"],
    ["AWS::IoTSiteWise::Project", "Arch_AWS-IoT-SiteWise_64-Project"],
    ["AWS::IoTTwinMaker::Entity", "Arch_AWS-IoT-TwinMaker_64-Entity"],
    ["AWS::IoTTwinMaker::Scene", "Arch_AWS-IoT-TwinMaker_64-Scene"],
    ["AWS::IoTTwinMaker::Workspace", "Arch_AWS-IoT-TwinMaker_64-Workspace"],
    ["AWS::KMS::Key", "AWS-Identity-and-Access-Management-IAM_Data-Encryption-Key_light-bg"],
    ["AWS::Kendra::Index", "Arch_Amazon-Kendra_64-Index"],
    ["AWS::Kinesis::Stream", "Arch_Amazon-Kinesis-Data-Streams_64"],
    ["AWS::Kinesis::StreamConsumer", "Arch_Amazon-Kinesis_64-StreamConsumer"],
    ["AWS::KinesisAnalyticsV2::Application", "Arch_Amazon-Kinesis-Data-Analytics_64-Application"],
    ["AWS::KinesisFirehose::DeliveryStream", "Arch_Amazon-Kinesis-Data-Firehose_64"],
    ["AWS::KinesisVideo::SignalingChannel", "Arch_Amazon-Kinesis-Video-Streams_64-SignalingChannel"],
    ["AWS::Lambda::EnvironmentVariable", "Env-variable-light-bg"],
    ["AWS::Lambda::Function", "AWS-Lambda-menu"],
    ["AWS::Lambda::Function-status-available", "AWS-Lambda"],
    ["AWS::Lambda::Function-status-negative", "AWS-Lambda-error"],
    ["AWS::Lambda::Function-status-warning", "AWS-Lambda-warning"],
    ["AWS::Lex::Bot", "Arch_Amazon-Lex_64-Bot"],
    ["AWS::Lex::BotAlias", "Arch_Amazon-Lex_64-BotAlias"],
    ["AWS::Lightsail::Bucket", "Arch_Amazon-Lightsail_64-Bucket"],
    ["AWS::Lightsail::StaticIp", "Arch_Amazon-Lightsail_64-StaticIp"],
    ["AWS::LookoutMetrics::Alert", "Arch_Amazon-Lookout-for-Metrics_64-Alert"],
    ["AWS::LookoutVision::Project", "Arch_Amazon-Lookout-for-Vision_64-Project"],
    ["AWS::MSK::Cluster", "Arch_Amazon-Managed-Streaming-for-Apache-Kafka_64"],
    ["AWS::MediaPackage::PackagingConfiguration", "Arch_AWS-Elemental-MediaPackage_64-PackagingConfiguration"],
    ["AWS::MediaPackage::PackagingGroup", "Arch_AWS-Elemental-MediaPackage_64-PackagingGroup"],
    ["AWS::NetworkManager::TransitGatewayRegistration", "Arch_AWS-Transit-Gateway_64-TransitGatewayRegistration"],
    ["AWS::OpenSearch::Domain", "Arch_Amazon-OpenSearch-Service_64"],
    ["AWS::Pinpoint::App", "Arch_Amazon-Pinpoint_64-App"],
    ["AWS::Pinpoint::ApplicationSettings", "Arch_Amazon-Pinpoint_64-ApplicationSettings"],
    ["AWS::Pinpoint::Campaign", "Arch_Amazon-Pinpoint_64-Campaign"],
    ["AWS::Pinpoint::InAppTemplate", "Arch_Amazon-Pinpoint_64-InAppTemplate"],
    ["AWS::Pinpoint::Segment", "Arch_Amazon-Pinpoint_64-Segment"],
    ["AWS::Pipes::Pipe", "Res_Amazon-EventBridge_Pipes_48"],
    ["AWS::QLDB::Ledger", "Amazon-Quantum-Ledger-Database_QLDB_light-bg-menu"],
    ["AWS::QLDB::Ledger-status-available", "Amazon-Quantum-Ledger-Database_QLDB_light-bg"],
    ["AWS::QLDB::Ledger-status-negative", "Amazon-Quantum-Ledger-Database_QLDB_light-bg-error"],
    ["AWS::QLDB::Ledger-status-warning", "Amazon-Quantum-Ledger-Database_QLDB_light-bg-warning"],
    ["AWS::RDS::DBCluster", "Amazon-DBCluster"],
    ["AWS::RDS::DBClusterSnapshot", "Amazon-DBClusterSnapshot"],
    ["AWS::RDS::DBInstance", "Amazon-RDS_Amazon-RDS_instance_light-bg-menu"],
    ["AWS::RDS::DBInstance-aurora", "Amazon-RDS_Amazon-Aurora_instance_light-bg-menu"],
    ["AWS::RDS::DBInstance-aurora-mysql", "Amazon-RDS_Amazon-Aurora_instance_mysql_light-bg-menu"],
    ["AWS::RDS::DBInstance-aurora-mysql-status-available", "Amazon-RDS_Amazon-Aurora_instance_mysql_light-bg"],
    ["AWS::RDS::DBInstance-aurora-mysql-status-negative", "Amazon-RDS_Amazon-Aurora_instance_mysql_light-bg-error"],
    ["AWS::RDS::DBInstance-aurora-mysql-status-warning", "Amazon-RDS_Amazon-Aurora_instance_mysql_light-bg-warning"],
    ["AWS::RDS::DBInstance-aurora-postgresql", "Amazon-RDS_Amazon-Aurora_instance_postgresql_light-bg-menu"],
    ["AWS::RDS::DBInstance-aurora-postgresql-status-available", "Amazon-RDS_Amazon-Aurora_instance_postgresql_light-bg"],
    ["AWS::RDS::DBInstance-aurora-postgresql-status-negative", "Amazon-RDS_Amazon-Aurora_instance_postgresql_light-bg-error"],
    ["AWS::RDS::DBInstance-aurora-postgresql-status-warning", "Amazon-RDS_Amazon-Aurora_instance_postgresql_light-bg-warning"],
    ["AWS::RDS::DBInstance-aurora-status-available", "Amazon-RDS_Amazon-Aurora_instance_light-bg"],
    ["AWS::RDS::DBInstance-aurora-status-negative", "Amazon-RDS_Amazon-Aurora_instance_light-bg-error"],
    ["AWS::RDS::DBInstance-aurora-status-warning", "Amazon-RDS_Amazon-Aurora_instance_light-bg-warning"],
    ["AWS::RDS::DBInstance-mariadb", "Amazon-RDS_MariaDB_instance_light-bg-menu"],
    ["AWS::RDS::DBInstance-mariadb-status-available", "Amazon-RDS_MariaDB_instance_light-bg"],
    ["AWS::RDS::DBInstance-mariadb-status-negative", "Amazon-RDS_MariaDB_instance_light-bg-error"],
    ["AWS::RDS::DBInstance-mariadb-status-warning", "Amazon-RDS_MariaDB_instance_light-bg-warning"],
    ["AWS::RDS::DBInstance-mysql", "Amazon-RDS_MySQL_instance_light-bg-menu"],
    ["AWS::RDS::DBInstance-mysql-status-available", "Amazon-RDS_MySQL_instance_light-bg"],
    ["AWS::RDS::DBInstance-mysql-status-negative", "Amazon-RDS_MySQL_instance_light-bg-error"],
    ["AWS::RDS::DBInstance-mysql-status-warning", "Amazon-RDS_MySQL_instance_light-bg-warning"],
    ["AWS::RDS::DBInstance-neptune", "Amazon-Neptune_light-bg-menu"],
    ["AWS::RDS::DBInstance-neptune-status-available", "Amazon-Neptune_light-bg"],
    ["AWS::RDS::DBInstance-neptune-status-negative", "Amazon-Neptune_light-bg-error"],
    ["AWS::RDS::DBInstance-neptune-status-warning", "Amazon-Neptune_light-bg-warning"],
    ["AWS::RDS::DBInstance-postgres", "Amazon-RDS_PostgreSQL_instance_light-bg-menu"],
    ["AWS::RDS::DBInstance-postgres-status-available", "Amazon-RDS_PostgreSQL_instance_light-bg"],
    ["AWS::RDS::DBInstance-postgres-status-negative", "Amazon-RDS_PostgreSQL_instance_light-bg-error"],
    ["AWS::RDS::DBInstance-postgres-status-warning", "Amazon-RDS_PostgreSQL_instance_light-bg-warning"],
    ["AWS::RDS::DBInstance-sqlserver-ex", "Amazon-RDS_SQL-Server_instance_light-bg-menu"],
    ["AWS::RDS::DBInstance-sqlserver-ex-status-available", "Amazon-RDS_SQL-Server_instance_light-bg"],
    ["AWS::RDS::DBInstance-sqlserver-ex-status-negative", "Amazon-RDS_SQL-Server_instance_light-bg-error"],
    ["AWS::RDS::DBInstance-sqlserver-ex-status-warning", "Amazon-RDS_SQL-Server_instance_light-bg-warning"],
    ["AWS::RDS::DBInstance-status-available", "Amazon-RDS_Amazon-RDS_instance_light-bg"],
    ["AWS::RDS::DBInstance-status-negative", "Amazon-RDS_Amazon-RDS_instance_light-bg-error"],
    ["AWS::RDS::DBInstance-status-warning", "Amazon-RDS_Amazon-RDS_instance_light-bg-warning"],
    ["AWS::RDS::DBSecurityGroup", "AWSDBSecurityGroup"],
    ["AWS::RDS::DBSnapshot", "Amazon-RDS_Amazon-RDS_Snapshot_light-bg"],
    ["AWS::RDS::DBSubnetGroup", "DBSubnetGroup"],
    ["AWS::RDS::GlobalCluster", "Res_Amazon-RDS_Multi-AZ-DB-Cluster_48-GlobalCluster"],
    ["AWS::RUM::AppMonitor", "Res_Amazon-CloudWatch_RUM_48"],
    ["AWS::Redshift::Cluster", "Amazon-Redshift_light-bg-menu"],
    ["AWS::Redshift::Cluster-status-available", "Amazon-Redshift_light-bg"],
    ["AWS::Redshift::Cluster-status-negative", "Amazon-Redshift_light-bg-error"],
    ["AWS::Redshift::Cluster-status-warning", "Amazon-Redshift_light-bg-warning"],
    ["AWS::Redshift::ClusterParameterGroup", "Amazon-Redshift_light-parameter-group"],
    ["AWS::Redshift::ClusterSnapshot", "Amazon-Redshift_light-snapshot"],
    ["AWS::Redshift::ClusterSubnetGroup", "Amazon-Redshift_light-subnet-group"],
    ["AWS::ResilienceHub::ResiliencyPolicy", "Arch_AWS-Resilience-Hub_64-ResiliencyPolicy"],
    ["AWS::RoboMaker::RobotApplication", "Arch_AWS-RoboMaker_64-RobotApplication"],
    ["AWS::RoboMaker::RobotApplicationVersion", "Res_AWS-RoboMaker_Development-Environment_48-RobotApplicationVersion"],
    ["AWS::RoboMaker::SimulationApplication", "Res_AWS-RoboMaker_Simulation_48-SimulationApplication"],
    ["AWS::Route53::HostedZone", "Res_Amazon-Route-53-Hosted-Zone_48"],
    ["AWS::Route53RecoveryControl::Cluster", "Arch_Amazon-Route-53_64-Cluster"],
    ["AWS::Route53RecoveryControl::ControlPanel", "Arch_Amazon-Route-53_64-ControlPanel"],
    ["AWS::Route53RecoveryControl::RoutingControl", "Arch_Amazon-Route-53_64-RoutingControl"],
    ["AWS::Route53RecoveryControl::SafetyRule", "Arch_Amazon-Route-53_64-SafetyRule"],
    ["AWS::Route53RecoveryReadiness::RecoveryGroup", "Res_Amazon-Route-53_Readiness-Checks_48-RecoveryGroup"],
    ["AWS::Route53RecoveryReadiness::ResourceSet", "Arch_Amazon-Route-53_64-ResourceSet"],
    ["AWS::Route53Resolver::FirewallDomainList", "Res_Amazon-Route-53_Resolver-DNS-Firewall_48"],
    ["AWS::Route53Resolver::ResolverRule", "Res_Amazon-Route-53_Resolver-Rule_48_Light"],
    ["AWS::Route53Resolver::ResolverRuleAssociation", "Res_Amazon-Route-53_Resolver-Rule-Association_48_Light"],
    ["AWS::Route53Resolver::ResolverRuleEndpoint", "Res_Amazon-Route-53_Resolver-Endpoint_48_Light"],
    ["AWS::S3::AccountPublicAccessBlock", "Amazon-S3_public_access_block"],
    ["AWS::S3::Bucket", "Amazon-Simple-Storage-Service-S3"],
    ["AWS::S3::MultiRegionAccessPoint", "Res_Amazon-Simple-Storage-Service_General-Access-Points_48-MultiRegionAccessPoint"],
    ["AWS::S3::StorageLens", "Res_Amazon-Simple-Storage-Service_S3-Storage-Lens_48"],
    ["AWS::SES::ConfigurationSet", "Res_Amazon-Simple-Email-Service_Email_48-ConfigurationSet"],
    ["AWS::SES::ContactList", "Res_Amazon-Simple-Email-Service_Email_48-ContactList"],
    ["AWS::SES::ReceiptFilter", "Res_Amazon-Simple-Email-Service_Email_48-ReceiptFilter"],
    ["AWS::SES::ReceiptRuleSet", "Res_Amazon-Simple-Email-Service_Email_48-ReceiptRuleSet"],
    ["AWS::SES::Template", "Res_Amazon-Simple-Email-Service_Email_48-Template"],
    ["AWS::SNS::Topic", "Amazon-Simple-Notification-Service-SNS_Topic_light-bg"],
    ["AWS::SQS::Queue", "Amazon-Simple-Queue-Service-SQS_Queue_light-bg"],
    ["AWS::SSM::AssociationCompliance", "Res_AWS-Systems-Manager_Application-Manager_48-AssociationCompliance"],
    ["AWS::SSM::ManagedInstanceInventory", "AWS-Systems-Manager_Inventory"],
    ["AWS::SageMaker::Domain", "Res_Amazon-SageMaker_Canvas_48-Domain"],
    ["AWS::SageMaker::EndpointConfig", "Arch_Amazon-SageMaker_64-EndpointConfig"],
    ["AWS::SageMaker::Model", "Res_Amazon-SageMaker_Model_48_Light"],
    ["AWS::SageMaker::NotebookInstance", "Res_Amazon-SageMaker_Notebook_48"],
    ["AWS::SageMaker::NotebookInstanceLifecycleConfig", "Res_Amazon-SageMaker_Notebook_48-NotebookInstanceLifestyleConfig"],
    ["AWS::SecretsManager::Secret", "Arch_AWS-Secrets-Manager_64-Secret"],
    ["AWS::Shield::Protection", "AWS-Shield-bg"],
    ["AWS::ShieldRegional::Protection", "AWS-Shield_regional-bg"],
    ["AWS::Signer::SigningProfile", "Arch_AWS-Signer_64-SigningProfile"],
    ["AWS::StepFunctions::Activity", "StepFunctionsActivity"],
    ["AWS::StepFunctions::StateMachine", "Arch_AWS-Step-Functions_64"],
    ["AWS::Tags::Tag", "AWS-Tags-bg"],
    ["AWS::Transfer::Agreement", "Arch-Category_Migration-Transfer_64-Agreement"],
    ["AWS::Transfer::Connector", "Arch-Category_Migration-Transfer_64-Connector"],
    ["AWS::Transfer::Workflow", "Arch-Category_Migration-Transfer_64-Workflow"],
    ["AWS::VPC::Endpoint", "Amazon-VPC_Endpoints_light-bg-menu"],
    ["AWS::VPC::Endpoint-status-available", "Amazon-VPC_Endpoints_light-bg"],
    ["AWS::VPC::Endpoint-status-negative", "Amazon-VPC_Endpoints_light-bg-error"],
    ["AWS::VPC::Endpoint-status-warning", "Amazon-VPC_Endpoints_light-bg-warning"],
    ["AWS::WAF::RateBasedRule", "AWS-WAF_rate-based-rule-bg"],
    ["AWS::WAF::Rule", "AWS-WAF_rule-bg"],
    ["AWS::WAF::RuleGroup", "AWS-WAF_rule-group-bg"],
    ["AWS::WAF::WebACL", "AWS-WAF_webacl-bg"],
    ["AWS::WAFRegional::RateBasedRule", "AWS-WAF_regional-rate-based-rule-bg"],
    ["AWS::WAFRegional::Rule", "AWS-WAF_regional-rule-bg"],
    ["AWS::WAFRegional::WebACL", "AWS-WAF_regional-webacl-bg"],
    ["AWS::WorkSpaces::ConnectionAlias", "WorkspacesConnectionAlias"],
    ["AWS::WorkSpaces::Workspace", "Arch_AWS-WorkSpaces_64"],
    ["DELETE", "API-Gateway-method-Delete_light-bg"],
    ["GET", "API-Gateway-method-Get_light-bg"],
    ["PATCH", "API-Gateway-method-Patch_light-bg"],
    ["POST", "API-Gateway-method-Post_light-bg"],
    ["PUT", "API-Gateway-method-Put_light-bg"],
    ["R5a-status-available", "Amazon-EC2_R5a-Instance_light-bg"],
    ["R5a-status-negative", "Amazon-EC2_R5a-Instance_light-bg-error"],
    ["R5a-status-warning", "Amazon-EC2_R5a-Instance_light-bg-warning"],
    ["R5b-status-available", "Amazon-EC2_R5b-Instance_light-bg"],
    ["R5b-status-negative", "Amazon-EC2_R5b-Instance_light-bg-error"],
    ["R5n-status-available", "Amazon-EC2_R5n-Instance_light-bg"],
    ["R5n-status-negative", "Amazon-EC2_R5n-Instance_light-bg-error"],
    ["R5n-status-warning", "Amazon-EC2_R5b-Instance_light-bg-warning"],
    ["R6i-status-available", "Amazon-EC2_R6i-Instance_light-bg"],
    ["R6i-status-negative", "Amazon-EC2_R6i-Instance_light-bg-error"],
    ["R6i-status-warning", "Amazon-EC2_R6i-Instance_light-bg-warning"],
    ["X2gd-status-available", "Amazon-EC2_X2gd-Instance_light-bg"],
    ["X2gd-status-negative", "Amazon-EC2_X2gd-Instance_light-bg-error"],
    ["X2gd-status-warning", "Amazon-EC2_X2gd-Instance_light-bg-warning"],
    ["X2idn-status-available", "Amazon-EC2_X2idn-Instance_light-bg"],
    ["X2idn-status-negative", "Amazon-EC2_X2idn-Instance_light-bg-error"],
    ["X2idn-status-warning", "Amazon-EC2_X2idn-Instance_light-bg-warning"],
    ["X2iedn-status-available", "Amazon-EC2_X2iedn-Instance_light-bg"],
    ["X2iedn-status-negative", "Amazon-EC2_X2iedn-Instance_light-bg-error"],
    ["X2iedn-status-warning", "Amazon-EC2_X2iedn-Instance_light-bg-warning"],
    ["X2iezn-status-available", "Amazon-EC2_X2iezn-Instance_light-bg"],
    ["X2iezn-status-negative", "Amazon-EC2_X2iezn-Instance_light-bg-error"],
    ["X2iezn-status-warning", "Amazon-EC2_X2iezn-Instance_light-bg-warning"],
    ["a1-status-available", "Amazon-EC2_A1-Instance_light-bg"],
    ["a1-status-negative", "Amazon-EC2_A1-Instance_light-bg-error"],
    ["a1-status-warning", "Amazon-EC2_A1-Instance_light-bg-warning"],
    ["account", "AWS-Cloud-alt_light-bg"],
    ["availabilityZone", "availabilityZone"],
    ["c4-status-available", "Amazon-EC2_C4-Instance_light-bg"],
    ["c4-status-negative", "Amazon-EC2_C4-Instance_light-bg-error"],
    ["c4-status-warning", "Amazon-EC2_C4-Instance_light-bg-warning"],
    ["c5-status-available", "Amazon-EC2_C5-Instance_light-bg"],
    ["c5-status-negative", "Amazon-EC2_C5-Instance_light-bg-error"],
    ["c5-status-warning", "Amazon-EC2_C5-Instance_light-bg-warning"],
    ["c5a-status-available", "Amazon-EC2_C5a-Instance_light-bg"],
    ["c5a-status-negative", "Amazon-EC2_C5a-Instance_light-bg-error"],
    ["c5a-status-warning", "Amazon-EC2_C5a-Instance_light-bg-warning"],
    ["c5n-status-available", "Amazon-EC2_C5n-Instance_light-bg"],
    ["c5n-status-negative", "Amazon-EC2_C5n-Instance_light-bg-error"],
    ["c5n-status-warning", "Amazon-EC2_C5n-Instance_light-bg-warning"],
    ["c6g-status-available", "Amazon-EC2_C6g-Instance_light-bg"],
    ["c6g-status-negative", "Amazon-EC2_C6g-Instance_light-bg-error"],
    ["c6g-status-warning", "Amazon-EC2_C6g-Instance_light-bg-warning"],
    ["c6gn-status-available", "Amazon-EC2_C6gn-Instance_light-bg"],
    ["c6gn-status-negative", "Amazon-EC2_C6gn-Instance_light-bg-error"],
    ["c6gn-status-warning", "Amazon-EC2_C6gn-Instance_light-bg-warning"],
    ["c6i-status-available", "Amazon-EC2_C6i-Instance_light-bg"],
    ["c6i-status-negative", "Amazon-EC2_C6i-Instance_light-bg-error"],
    ["c6i-status-warning", "Amazon-EC2_C6i-Instance_light-bg-warning"],
    ["c7g-status-available", "Amazon-EC2_C7g-Instance_light-bg"],
    ["c7g-status-negative", "Amazon-EC2_C7g-Instance_light-bg-error"],
    ["c7g-status-warning", "Amazon-EC2_C7g-Instance_light-bg-warning"],
    ["cloud", "AWS-Cloud_light-bg"],
    ["collapse", "treeview-collapse"],
    ["collapse_icon", "remove_circle"],
    ["d2-status-available", "Amazon-EC2_D2-Instance_light-bg"],
    ["d2-status-negative", "Amazon-EC2_D2-Instance_light-bg-error"],
    ["d2-status-warning", "Amazon-EC2_D2-Instance_light-bg-warning"],
    ["d3-status-available", "Amazon-EC2_D3-Instance_light-bg"],
    ["d3-status-negative", "Amazon-EC2_D3-Instance_light-bg-error"],
    ["d3-status-warning", "Amazon-EC2_D3-Instance_light-bg-warning"],
    ["d3en-status-available", "Amazon-EC2_D3en-Instance_light-bg"],
    ["d3en-status-negative", "Amazon-EC2_D3en-Instance_light-bg-error"],
    ["d3en-status-warning", "Amazon-EC2_D3en-Instance_light-bg-warning"],
    ["default-icon", "default-icon"],
    ["dl1-status-available", "Amazon-EC2_DL1-Instance_light-bg"],
    ["dl1-status-negative", "Amazon-EC2_DL1-Instance_light-bg-error"],
    ["dl1-status-warning", "Amazon-EC2_DL1-Instance_light-bg-warning"],
    ["expand", "treeview-expand"],
    ["expand_icon", "add_circle"],
    ["f1-status-available", "Amazon-EC2_F1-Instance_light-bg"],
    ["f1-status-negative", "Amazon-EC2_F1-Instance_light-bg-error"],
    ["f1-status-warning", "Amazon-EC2_F1-Instance_light-bg-warning"],
    ["g3-status-available", "Amazon-EC2_G3-Instance_light-bg"],
    ["g3-status-negative", "Amazon-EC2_G3-Instance_light-bg-error"],
    ["g3-status-warning", "Amazon-EC2_G3-Instance_light-bg-warning"],
    ["g4ad-status-available", "Amazon-EC2_G4ad-Instance_light-bg"],
    ["g4ad-status-negative", "Amazon-EC2_G4ad-Instance_light-bg-error"],
    ["g4ad-status-warning", "Amazon-EC2_G4ad-Instance_light-bg-warning"],
    ["g4dn-status-available", "Amazon-EC2_G4dn-Instance_light-bg"],
    ["g4dn-status-negative", "Amazon-EC2_G4dn-Instance_light-bg-error"],
    ["g4dn-status-warning", "Amazon-EC2_G4dn-Instance_light-bg-warning"],
    ["g5-status-available", "Amazon-EC2_G5-Instance_light-bg"],
    ["g5-status-negative", "Amazon-EC2_G5-Instance_light-bg-error"],
    ["g5-status-warning", "Amazon-EC2_G5-Instance_light-bg-warning"],
    ["g5g-status-available", "Amazon-EC2_G5g-Instance_light-bg"],
    ["g5g-status-negative", "Amazon-EC2_G5g-Instance_light-bg-error"],
    ["g5g-status-warning", "Amazon-EC2_G5g-Instance_light-bg-warning"],
    ["h1-status-available", "Amazon-EC2_H1-Instance_light-bg"],
    ["h1-status-negative", "Amazon-EC2_H1-Instance_light-bg-error"],
    ["h1-status-warning", "Amazon-EC2_H1-Instance_light-bg-warning"],
    ["i3-status-available", "Amazon-EC2_I3-Instance_light-bg"],
    ["i3-status-negative", "Amazon-EC2_I3-Instance_light-bg-error"],
    ["i3-status-warning", "Amazon-EC2_I3-Instance_light-bg-warning"],
    ["i3en-status-available", "Amazon-EC2_I3en-Instance_light-bg"],
    ["i3en-status-negative", "Amazon-EC2_I3en-Instance_light-bg-error"],
    ["i3en-status-warning", "Amazon-EC2_I3en-Instance_light-bg-warning"],
    ["i4i-status-available", "Amazon-EC2_I4i-Instance_light-bg"],
    ["i4i-status-negative", "Amazon-EC2_I4i-Instance_light-bg-error"],
    ["i4i-status-warning", "Amazon-EC2_I4i-Instance_light-bg-warning"],
    ["im4gn-status-available", "Amazon-EC2_IM4gn-Instance_light-bg"],
    ["im4gn-status-negative", "Amazon-EC2_IM4gn-Instance_light-bg-error"],
    ["im4gn-status-warning", "Amazon-EC2_IM4gn-Instance_light-bg-warning"],
    ["inf1-status-available", "Amazon-EC2_INF1-Instance_light-bg"],
    ["inf1-status-negative", "Amazon-EC2_INF1-Instance_light-bg-error"],
    ["inf1-status-warning", "Amazon-EC2_INF1-Instance_light-bg-warning"],
    ["is4gen-status-available", "Amazon-EC2_IS4gen-Instance_light-bg"],
    ["is4gen-status-negative", "Amazon-EC2_IS4gen-Instance_light-bg-error"],
    ["is4gen-status-warning", "Amazon-EC2_IS4gen-Instance_light-bg-warning"],
    ["logo", "AWS-Zoom_light-bg"],
    ["logo-transparent", "AWS-Zoom-trans-bg"],
    ["m1-status-available", "Amazon-EC2_M1-Instance_light-bg"],
    ["m1-status-negative", "Amazon-EC2_M1-Instance_light-bg-error"],
    ["m1-status-warning", "Amazon-EC2_M1-Instance_light-bg-warning"],
    ["m4-status-available", "Amazon-EC2_M4-Instance_light-bg"],
    ["m4-status-negative", "Amazon-EC2_M4-Instance_light-bg-error"],
    ["m4-status-warning", "Amazon-EC2_M4-Instance_light-bg-warning"],
    ["m5-status-available", "Amazon-EC2_M5-Instance_light-bg"],
    ["m5-status-negative", "Amazon-EC2_M5-Instance_light-bg-error"],
    ["m5-status-warning", "Amazon-EC2_M5-Instance_light-bg-warning"],
    ["m5a-status-available", "Amazon-EC2_M5a-Instance_light-bg"],
    ["m5a-status-negative", "Amazon-EC2_M5a-Instance_light-bg-error"],
    ["m5a-status-warning", "Amazon-EC2_M5a-Instance_light-bg-warning"],
    ["m5n-status-available", "Amazon-EC2_M5n-Instance_light-bg"],
    ["m5n-status-negative", "Amazon-EC2_M5n-Instance_light-bg-error"],
    ["m5n-status-warning", "Amazon-EC2_M5n-Instance_light-bg-warning"],
    ["m5zn-status-available", "Amazon-EC2_M5zn-Instance_light-bg"],
    ["m5zn-status-negative", "Amazon-EC2_M5zn-Instance_light-bg-error"],
    ["m5zn-status-warning", "Amazon-EC2_M5zn-Instance_light-bg-warning"],
    ["m6g-status-available", "Amazon-EC2_M6g-Instance_light-bg"],
    ["m6g-status-negative", "Amazon-EC2_M6g-Instance_light-bg-error"],
    ["m6g-status-warning", "Amazon-EC2_M6g-Instance_light-bg-warning"],
    ["m6i-status-available", "Amazon-EC2_M6i-Instance_light-bg"],
    ["m6i-status-negative", "Amazon-EC2_M6i-Instance_light-bg-error"],
    ["m6i-status-warning", "Amazon-EC2_M6i-Instance_light-bg-warning"],
    ["mac1-status-available", "Amazon-EC2_MAC-Instance_light-bg"],
    ["mac1-status-negative", "Amazon-EC2_MAC-Instance_light-bg-error"],
    ["mac1-status-warning", "Amazon-EC2_MAC-Instance_light-bg-warning"],
    ["p2-status-available", "Amazon-EC2_P2-Instance_light-bg"],
    ["p2-status-negative", "Amazon-EC2_P2-Instance_light-bg-error"],
    ["p2-status-warning", "Amazon-EC2_P2-Instance_light-bg-warning"],
    ["p3-status-available", "Amazon-EC2_P3-Instance_light-bg"],
    ["p3-status-negative", "Amazon-EC2_P3-Instance_light-bg-error"],
    ["p3-status-warning", "Amazon-EC2_P3-Instance_light-bg-warning"],
    ["p3dn-status-available", "Amazon-EC2_P3dn-Instance_light-bg"],
    ["p3dn-status-negative", "Amazon-EC2_P3dn-Instance_light-bg-error"],
    ["p3dn-status-warning", "Amazon-EC2_P3dn-Instance_light-bg-warning"],
    ["p4d-status-available", "Amazon-EC2_P4-Instance_light-bg"],
    ["p4d-status-negative", "Amazon-EC2_P4-Instance_light-bg-error"],
    ["p4d-status-warning", "Amazon-EC2_P4-Instance_light-bg-warning"],
    ["r4-status-available", "Amazon-EC2_R4-Instance_light-bg"],
    ["r4-status-negative", "Amazon-EC2_R4-Instance_light-bg-error"],
    ["r4-status-warning", "Amazon-EC2_R4-Instance_light-bg-warning"],
    ["r5-status-available", "Amazon-EC2_R5-Instance_light-bg"],
    ["r5-status-negative", "Amazon-EC2_R5-Instance_light-bg-error"],
    ["r5-status-warning", "Amazon-EC2_R5n-Instance_light-bg-warning"],
    ["r6g-status-available", "Amazon-EC2_R6g-Instance_light-bg"],
    ["r6g-status-negative", "Amazon-EC2_R6g-Instance_light-bg-error"],
    ["r6g-status-warning", "Amazon-EC2_R6g-Instance_light-bg-warning"],
    ["region", "Region_light-bg"],
    ["search", "search"],
    ["status-available", "status-positive"],
    ["status-negative", "status-negative"],
    ["status-warning", "status-warning"],
    ["subnet", "VPC-subnet-private_light-bg-collapsed"],
    ["t2-status-available", "Amazon-EC2_T2-Instance_light-bg"],
    ["t2-status-negative", "Amazon-EC2_T2-Instance_light-bg-error"],
    ["t2-status-warning", "Amazon-EC2_T2-Instance_light-bg-warning"],
    ["t3-status-available", "Amazon-EC2_T3-Instance_light-bg"],
    ["t3-status-negative", "Amazon-EC2_T3-Instance_light-bg-error"],
    ["t3-status-warning", "Amazon-EC2_T3-Instance_light-bg-warning"],
    ["t3a-status-available", "Amazon-EC2_T3a-Instance_light-bg"],
    ["t3a-status-negative", "Amazon-EC2_T3a-Instance_light-bg-error"],
    ["t3a-status-warning", "Amazon-EC2_T3a-Instance_light-bg-warning"],
    ["t4g-status-available", "Amazon-EC2_T4g-Instance_light-bg"],
    ["t4g-status-negative", "Amazon-EC2_T4g-Instance_light-bg-error"],
    ["t4g-status-warning", "Amazon-EC2_T4g-Instance_light-bg-warning"],
    ["trn1-status-available", "Amazon-EC2_TRN1-Instance_light-bg"],
    ["trn1-status-negative", "Amazon-EC2_TRN1-Instance_light-bg-error"],
    ["trn1-status-warning", "Amazon-EC2_TRN1-Instance_light-bg-warning"],
    ["u-12tb1-status-available", "Amazon-EC2_HMem12-Instance_light-bg"],
    ["u-12tb1-status-negative", "Amazon-EC2_HMem12-Instance_light-bg-error"],
    ["u-12tb1-status-warning", "Amazon-EC2_HMem12-Instance_light-bg-warning"],
    ["u-18tb1-status-available", "Amazon-EC2_HMem18-Instance_light-bg"],
    ["u-18tb1-status-negative", "Amazon-EC2_HMem18-Instance_light-bg-error"],
    ["u-18tb1-status-warning", "Amazon-EC2_HMem18-Instance_light-bg-warning"],
    ["u-24tb1-status-available", "Amazon-EC2_HMem24-Instance_light-bg"],
    ["u-24tb1-status-negative", "Amazon-EC2_HMem24-Instance_light-bg-error"],
    ["u-24tb1-status-warning", "Amazon-EC2_HMem24-Instance_light-bg-warning"],
    ["u-6tb1-status-available", "Amazon-EC2_HMem6-Instance_light-bg"],
    ["u-6tb1-status-negative", "Amazon-EC2_HMem6-Instance_light-bg-error"],
    ["u-6tb1-status-warning", "Amazon-EC2_HMem6-Instance_light-bg-warning"],
    ["u-9tb1-status-available", "Amazon-EC2_HMem9-Instance_light-bg"],
    ["u-9tb1-status-negative", "Amazon-EC2_HMem9-Instance_light-bg-error"],
    ["u-9tb1-status-warning", "Amazon-EC2_HMem9-Instance_light-bg-warning"],
    ["vpc", "VPC-collapsed"],
    ["vt1-status-available", "Amazon-EC2_VT1-Instance_light-bg"],
    ["vt1-status-negative", "Amazon-EC2_VT1-Instance_light-bg-error"],
    ["vt1-status-warning", "Amazon-EC2_VT1-Instance_light-bg-warning"],
    ["x1-status-available", "Amazon-EC2_X1-Instance_light-bg"],
    ["x1-status-negative", "Amazon-EC2_X1-Instance_light-bg-error"],
    ["x1-status-warning", "Amazon-EC2_X1-Instance_light-bg-warning"],
    ["x1e-status-available", "Amazon-EC2_X1e-Instance_light-bg"],
    ["x1e-status-negative", "Amazon-EC2_X1e-Instance_light-bg-error"],
    ["x1e-status-warning", "Amazon-EC2_X1e-Instance_light-bg-warning"],
    ["z1d-status-available", "Amazon-EC2_z1d-Instance_light-bg"],
    ["z1d-status-negative", "Amazon-EC2_z1d-Instance_light-bg-error"],
    ["z1d-status-warning", "Amazon-EC2_z1d-Instance_light-bg-warning"]
  ]
);
export const fetchImage = (type, state) => {
  let image;
  if (state && type) {
    image = icons.get(`${type}-${state.status}`);
  } else if (state && !type) {
    image = icons.get(state.status);
  } else if (!state && type) {
    image = icons.get(type);
  } else {
    image = icons.get("default-icon");
  }
  return image ? buildURL(image) : void 0;
};
const buildURL = (image) => {
  return `/icons/${image}.svg`;
};
export const fetchLogo = (transparent) => {
  return transparent ? `/icons/${icons.get("logo-transparent")}.svg` : `/icons/${icons.get("logo")}.svg`;
};
