import React from "react";
import { HelpPanel } from "@cloudscape-design/components";
import { matchPath, useLocation } from "react-router-dom";
import { EDIT_VIEW } from "../../../../routes";
const ViewFormHelper = () => {
  const location = useLocation();
  const isEdit = matchPath(location.pathname, {
    path: EDIT_VIEW,
    exact: true
  });
  return /* @__PURE__ */ React.createElement(HelpPanel, { header: /* @__PURE__ */ React.createElement("h2", null, isEdit ? "Edit" : "Create", " a View") }, /* @__PURE__ */ React.createElement("p", null, "Build a View of specific AWS Resources."), /* @__PURE__ */ React.createElement("dl", null, /* @__PURE__ */ React.createElement("dd", null, /* @__PURE__ */ React.createElement("ol", null, /* @__PURE__ */ React.createElement("li", null, "Select ", /* @__PURE__ */ React.createElement("strong", null, "AWS Accounts"), "."), /* @__PURE__ */ React.createElement("li", null, "Select ", /* @__PURE__ */ React.createElement("strong", null, "AWS Regions (optional)"), "."), /* @__PURE__ */ React.createElement("li", null, "Select ", /* @__PURE__ */ React.createElement("strong", null, "AWS Resource types"), "."), /* @__PURE__ */ React.createElement("li", null, "Choose ", /* @__PURE__ */ React.createElement("strong", null, "Save"), ".")))));
};
export default ViewFormHelper;
