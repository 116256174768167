import { reduce, evolve, add, head, tail, curry, groupBy, values, prop, map, addIndex } from "ramda";
export const sumBy = (prop2) => (vals) => reduce(
  (current, val) => evolve({ [prop2]: add(val[prop2]) }, current),
  head(vals),
  tail(vals)
);
export const groupSumBy = curry(
  (groupOn, sumOn, vals) => values(map(sumBy(sumOn))(groupBy(prop(groupOn), vals)))
);
export const mapIndexed = addIndex(map);
