import React from "react";
import CostBreakdownTable from "./CostBreakdownTable";
import PropTypes from "prop-types";
import CostBreakdownChart from "./CostBreakdownChart";
import { Box, Container, Spinner } from "@cloudscape-design/components";
const CostBreakdown = ({
  resources,
  setSelectedItems,
  resourceDailyBreakdown,
  loading = false
}) => {
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    CostBreakdownTable,
    {
      resources,
      onSelectionChange: setSelectedItems
    }
  ), loading ? /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Box, { textAlign: "center" }, /* @__PURE__ */ React.createElement(Spinner, { size: "large" }))) : /* @__PURE__ */ React.createElement(CostBreakdownChart, { items: resourceDailyBreakdown }));
};
CostBreakdown.propTypes = {
  loading: PropTypes.bool.isRequired,
  resources: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  resourceDailyBreakdown: PropTypes.array.isRequired
};
export default CostBreakdown;
