import React from "react";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import TextFilter from "@cloudscape-design/components/text-filter";
import Pagination from "@cloudscape-design/components/pagination";
import { useCollection } from "@cloudscape-design/collection-hooks";
export const ResourceDetailsTagTable = ({
  trackBy,
  rows,
  columns,
  sortColumn,
  pageSize,
  visibleColumns
}) => {
  const {
    items,
    filterProps,
    collectionProps,
    paginationProps
  } = useCollection(rows, {
    filtering: {
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No tags"), /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No tags to display.")),
      noMatch: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No match"), /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No tags matched."))
    },
    pagination: { pageSize },
    sorting: { sortingColumn: sortColumn }
  });
  return /* @__PURE__ */ React.createElement(
    Table,
    {
      ...collectionProps,
      trackBy,
      resizableColumns: true,
      stickyHeader: true,
      columnDefinitions: columns,
      items,
      loadingText: "Loading...",
      visibleColumns,
      filter: /* @__PURE__ */ React.createElement(TextFilter, { ...filterProps, filteringPlaceholder: "Find tags" }),
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No tags"), /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No tags to display.")),
      pagination: /* @__PURE__ */ React.createElement(Pagination, { ...paginationProps })
    }
  );
};
export default ResourceDetailsTagTable;
