import React, { useState } from "react";
import {
  Alert,
  Button,
  Container,
  Form,
  FormField,
  Header,
  Input,
  SpaceBetween
} from "@cloudscape-design/components";
import PropTypes from "prop-types";
import { privateLevel, useListObjects, viewsPrefix } from "../../../Hooks/useS3Objects";
import * as R from "ramda";
const ViewFormDetailsSection = ({ view, onSubmit }) => {
  const [viewName, setViewName] = useState(view.name ?? "");
  const { data: existingViews = [] } = useListObjects(viewsPrefix, privateLevel);
  const validName = R.allPass([
    (x) => R.equals(R.length(R.match(/[!@#$%^&*(),.?":{}|<>]/g, x)), 0),
    (x) => R.and(R.gt(R.length(x), 0), R.lte(R.length(x), 64))
  ]);
  const isAnOverwrite = () => R.includes(
    viewName,
    R.map((e) => R.split("/", e.key)[1], existingViews)
  );
  return /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(
    Form,
    {
      actions: /* @__PURE__ */ React.createElement(
        Button,
        {
          iconAlign: "right",
          disabled: R.and(
            R.isEmpty(view.selectedAccounts),
            R.isEmpty(view.selectedResourceTypes)
          ),
          onClick: () => onSubmit(viewName),
          variant: "primary"
        },
        "Save"
      )
    },
    /* @__PURE__ */ React.createElement(Container, { header: /* @__PURE__ */ React.createElement(Header, { variant: "h2" }, "View Details") }, /* @__PURE__ */ React.createElement(SpaceBetween, { size: "s" }, /* @__PURE__ */ React.createElement(
      FormField,
      {
        stretch: true,
        errorText: !validName(viewName) ? "Should be fewer than 64 characters and contain no special characters" : null,
        label: "Name",
        description: "The name that will identify this View."
      },
      /* @__PURE__ */ React.createElement(
        Input,
        {
          onChange: ({ detail }) => setViewName(detail.value),
          value: viewName
        }
      )
    ), isAnOverwrite() && /* @__PURE__ */ React.createElement(
      Alert,
      {
        type: "warning",
        dismissAriaLabel: "Close alert",
        header: "Overwrite Existing View"
      },
      "Workload Discovery on AWS will Overwrite the existing view ",
      /* @__PURE__ */ React.createElement("strong", null, viewName)
    )))
  ));
};
ViewFormDetailsSection.propTypes = {
  view: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};
export default ViewFormDetailsSection;
