import React from "react";
import {
  Table,
  Box,
  TextFilter,
  Header,
  Pagination
} from "@cloudscape-design/components";
import { fetchImage } from "../../../../../Utils/ImageSelector";
import { useCollection } from "@cloudscape-design/collection-hooks";
import PropTypes from "prop-types";
import * as R from "ramda";
import { useResourcesAccountMetadata, useResourcesMetadata } from "../../../../Hooks/useResourcesMetadata";
import { groupSumBy } from "../../../../../Utils/ArrayUtils";
import {
  createTableAriaLabels
} from "../../../../../Utils/AccessibilityUtils";
const mapIndexed = R.addIndex(R.map);
const ResourcesTypes = ({ accounts, onSelection }) => {
  const { data: resources = { accounts: [] }, isLoading: loadingResources } = useResourcesMetadata();
  const accountsFilter = R.isEmpty(accounts) ? resources.accounts : accounts;
  const [selectedItems, setSelectedItems] = React.useState([]);
  const { data = [], isLoading } = useResourcesAccountMetadata(accountsFilter, { batchSize: 50 });
  const resourceTypes = R.compose(
    groupSumBy("type", "count"),
    R.chain((e) => e.resourceTypes)
  )(data);
  const onSelectionChange = (items2) => {
    setSelectedItems(items2);
    onSelection(items2);
  };
  const getResourceCards = () => mapIndexed((resourceType) => {
    return {
      id: resourceType.type,
      type: resourceType.type,
      icon: /* @__PURE__ */ React.createElement("img", { src: fetchImage(resourceType.type), style: { width: "25px", minHeight: "25px" } }),
      resourceCount: resourceType.count
    };
  }, resourceTypes);
  const {
    items,
    filterProps,
    collectionProps,
    paginationProps
  } = useCollection(getResourceCards(), {
    filtering: {
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No resources"), /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No resources to display.")),
      noMatch: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No match"), /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No resources matched."))
    },
    pagination: { pageSize: 10 },
    sorting: {}
  });
  return /* @__PURE__ */ React.createElement(
    Table,
    {
      trackBy: "type",
      filter: /* @__PURE__ */ React.createElement(
        TextFilter,
        {
          ...filterProps,
          filteringPlaceholder: "Find a resource type"
        }
      ),
      ariaLabels: createTableAriaLabels("resource type", "resource types", {
        keys: ["type"],
        fallback: "Unknown resource type"
      }, "Resources types"),
      ...collectionProps,
      loading: loadingResources || isLoading,
      columnDefinitions: [
        {
          id: "icon",
          header: "Icon",
          cell: (item) => item.icon,
          width: 100,
          minWidth: 100
        },
        {
          id: "type",
          header: "Type",
          cell: (item) => item.type,
          width: 275,
          minWidth: 275
        },
        {
          id: "resourceCount",
          header: "Count",
          cell: (item) => item.resourceCount,
          sortingField: "resourceCount",
          width: 75,
          minWidth: 50
        }
      ],
      onSelectionChange: ({ detail }) => onSelectionChange(detail.selectedItems),
      selectedItems,
      items,
      resizableColumns: true,
      loadingText: "Loading resource types",
      selectionType: "multi",
      visibleColumns: ["icon", "type", "resourceCount"],
      empty: /* @__PURE__ */ React.createElement(Box, { textAlign: "center", color: "inherit" }, /* @__PURE__ */ React.createElement("b", null, "No resources"), /* @__PURE__ */ React.createElement(Box, { padding: { bottom: "s" }, variant: "p", color: "inherit" }, "No resources to display.")),
      header: /* @__PURE__ */ React.createElement(Header, { counter: `(${getResourceCards().length})` }, "Resources types"),
      pagination: /* @__PURE__ */ React.createElement(Pagination, { ...paginationProps })
    }
  );
};
ResourcesTypes.propTypes = {
  accounts: PropTypes.array,
  onSelection: PropTypes.func.isRequired
};
export default ResourcesTypes;
