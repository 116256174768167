import React from "react";
import {
  Box,
  Container,
  Header,
  ColumnLayout,
  TextContent,
  SpaceBetween
} from "@cloudscape-design/components";
import CostBreakdownCharts from "./CostBreakdownCharts";
import PropTypes from "prop-types";
import * as R from "ramda";
const CostBreakdownSummary = ({ resources }) => {
  const byAccount = R.groupBy((e) => e.data.resource.accountId);
  const byRegion = R.groupBy((e) => e.data.resource.region);
  return /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "vertical", size: "l" }, /* @__PURE__ */ React.createElement(
    Container,
    {
      header: /* @__PURE__ */ React.createElement(
        Header,
        {
          description: "Information associated with the cost of this workload",
          variant: "h2"
        },
        "Summary"
      )
    },
    /* @__PURE__ */ React.createElement(ColumnLayout, { disableGutters: true, columns: "4", variant: "text-grid" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Box, { margin: { bottom: "xxxs" }, color: "text-label" }, "Estimated cost"), /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, `$${R.reduce(
      (acc, val) => acc + parseFloat(val.data.cost),
      0,
      resources
    ).toFixed(2)}`))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Box, { margin: { bottom: "xxxs" }, color: "text-label" }, "Accounts"), /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, R.length(R.keys(byAccount(resources)))))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Box, { margin: { bottom: "xxxs" }, color: "text-label" }, "Regions"), /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, R.length(R.keys(byRegion(resources)))))), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Box, { margin: { bottom: "xxxs" }, color: "text-label" }, "Resources"), /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, R.length(resources)))))
  ), /* @__PURE__ */ React.createElement(CostBreakdownCharts, { resources }));
};
CostBreakdownSummary.propTypes = {
  resources: PropTypes.array.isRequired
};
export default CostBreakdownSummary;
