import React, { useEffect } from "react";
import { ColumnLayout } from "@cloudscape-design/components";
import ResourcesTable from "./ResourcesTable";
import ResourceDetails from "../../../Shared/ResourceDetails";
import { useDiagramSettingsState } from "../../../Contexts/DiagramSettingsContext";
import * as R from "ramda";
const DiagramSplitPanelContents = () => {
  const [{ selectedResources }] = useDiagramSettingsState();
  const [selectedResource, setSelectedResource] = React.useState([]);
  const [processedResources, setProcessedResources] = React.useState([]);
  useEffect(() => {
    const getResources = () => R.uniq(
      R.filter(
        (n) => R.equals(n.group(), "nodes") && R.equals(n.data("type"), "resource"),
        R.chain(
          (r) => r.isParent() ? r.descendants() : r,
          selectedResources ?? []
        )
      )
    );
    setProcessedResources(
      R.map((e) => {
        return {
          id: e.data("id"),
          name: e.data("title"),
          data: e.data()
        };
      }, getResources())
    );
  }, [selectedResources]);
  useEffect(() => {
    setSelectedResource([]);
  }, [selectedResources]);
  return /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 2 }, /* @__PURE__ */ React.createElement(
    ResourcesTable,
    {
      resources: processedResources,
      selectedResource,
      setSelectedResource
    }
  ), !R.isEmpty(selectedResource) && /* @__PURE__ */ React.createElement(ResourceDetails, { selectedResource: R.head(selectedResource.map((x) => x.data)) }));
};
export default DiagramSplitPanelContents;
