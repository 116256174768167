import ResourcesSplitPanel from "./components/Explore/Resources/Utils/ResourcesSplitPanel";
import React from "react";
import DrawingSplitPanel from "./components/Diagrams/Draw/Utils/DrawingSplitPanel";
import { OPEN_DIAGRAM, RESOURCES, VIEWS } from "./routes";
const splitPanels = [
  {
    title: "Draw",
    path: OPEN_DIAGRAM,
    component: /* @__PURE__ */ React.createElement(DrawingSplitPanel, null)
  },
  {
    title: "Resources",
    path: RESOURCES,
    component: /* @__PURE__ */ React.createElement(ResourcesSplitPanel, null)
  },
  {
    title: "Views",
    path: VIEWS,
    component: /* @__PURE__ */ React.createElement(ResourcesSplitPanel, null)
  }
];
export default splitPanels;
