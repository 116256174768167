import React from "react";
import {
  ColumnLayout,
  Container,
  Header,
  TextContent
} from "@cloudscape-design/components";
import PropTypes from "prop-types";
import * as R from "ramda";
import ValueWithLabel from "../../../Shared/ValueWithLabel";
const ViewOverview = ({ accounts, resourceTypes }) => {
  const accountsToRegionList = R.uniq(R.chain((i) => i.regions, accounts));
  return /* @__PURE__ */ React.createElement(
    Container,
    {
      header: /* @__PURE__ */ React.createElement(Header, null, "Overview")
    },
    /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 4, variant: "text-grid" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Accounts" }, /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, R.length(accounts)))), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Regions" }, /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, R.length(accountsToRegionList)))), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Resources types" }, /* @__PURE__ */ React.createElement(TextContent, null, /* @__PURE__ */ React.createElement("h1", null, R.length(resourceTypes)))))
  );
};
ViewOverview.propTypes = {
  accounts: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  resourceTypes: PropTypes.array.isRequired
};
export default ViewOverview;
