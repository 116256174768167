import React from "react";
import { readString } from "react-papaparse";
import { SpaceBetween, Button } from "@cloudscape-design/components";
import PropTypes from "prop-types";
const reader = new FileReader();
import * as R from "ramda";
const FileUploader = ({ validateAndUpload, onError }) => {
  const hiddenFileInput = React.useRef(null);
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded && fileUploaded.size >= 2048) {
      onError([
        {
          item: null,
          message: "File is too large. It must be under 2KB"
        }
      ]);
    } else {
      reader.readAsText(fileUploaded);
      reader.onload = function() {
        validateAndUpload(
          R.map(
            (e) => R.pick(["accountId", "accountName", "region"], e),
            readString(reader.result, {
              header: true,
              delimiter: ",",
              skipEmptyLines: true
            }).data
          )
        );
      };
      reader.onerror = function() {
        console.error(reader.error);
      };
    }
  };
  return /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "vertical", size: "xs" }, /* @__PURE__ */ React.createElement(Button, { iconName: "upload", onClick: handleClick }, "Upload a CSV"), /* @__PURE__ */ React.createElement(
    "input",
    {
      id: "tempInput",
      accept: ".csv,text/csv",
      type: "file",
      ref: hiddenFileInput,
      onChange: handleChange,
      style: { display: "none" }
    }
  ));
};
FileUploader.propTypes = {
  validateAndUpload: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};
export default FileUploader;
