import React from "react";
import {
  Button,
  SpaceBetween,
  Box
} from "@cloudscape-design/components";
import { GLOBAL_TEMPLATE, REGIONAL_TEMPLATE, useTemplate } from "../../Hooks/useTemplate";
import fileDownload from "js-file-download";
import { GLOBAL_RESOURCES_TEMPLATE_FILENAME } from "../../../config/constants";
const TemplateProvider = () => {
  const { data: globalTemplate, isLoading: isLoadingGlobal } = useTemplate(GLOBAL_TEMPLATE);
  const { data: regionalTemplate, isLoading: isLoadingRegional } = useTemplate(REGIONAL_TEMPLATE);
  return /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(SpaceBetween, { direction: "vertical", size: "s" }, /* @__PURE__ */ React.createElement(Box, { variant: "p" }, "Using the AWS CloudFormation templates below:", /* @__PURE__ */ React.createElement("ul", null, /* @__PURE__ */ React.createElement("li", null, "Deploy the ", /* @__PURE__ */ React.createElement("strong", null, "Global Resources"), " template once in each target account."), /* @__PURE__ */ React.createElement("li", null, "Deploy the ", /* @__PURE__ */ React.createElement("strong", null, "Regional Resources"), " template to each AWS region in a target account that you want to be discoverable.")), "For a target account to be discovered you must deploy the ", /* @__PURE__ */ React.createElement("strong", null, "Global Resources"), " template exactly once, and the ", /* @__PURE__ */ React.createElement("strong", null, "Regional Resources"), " template at least once."), /* @__PURE__ */ React.createElement(Box, null, /* @__PURE__ */ React.createElement(SpaceBetween, { size: "s", direction: "horizontal" }, /* @__PURE__ */ React.createElement(
    Button,
    {
      iconName: "download",
      disabled: isLoadingGlobal,
      onClick: async () => fileDownload(
        globalTemplate,
        GLOBAL_RESOURCES_TEMPLATE_FILENAME
      )
    },
    "Global Resources"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      iconName: "download",
      disabled: isLoadingRegional,
      onClick: async () => fileDownload(
        regionalTemplate,
        "regional-resources.template"
      )
    },
    "Regional Resources"
  )))));
};
export default TemplateProvider;
