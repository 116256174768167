export const getGridLayout = (bounds) => {
  return {
    name: "grid",
    fit: false,
    // whether to fit the viewport to the graph
    padding: 1,
    // padding used on fit
    boundingBox: bounds,
    // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
    avoidOverlap: true,
    // prevents node overlap, may overflow boundingBox if not enough space
    avoidOverlapPadding: 0,
    // extra spacing around nodes when avoidOverlap: true
    nodeDimensionsIncludeLabels: true,
    // Excludes the label when calculating node bounding boxes for the layout algorithm
    spacingFactor: void 0,
    // Applies a multiplicative factor (>0) to expand or compress the overall area that the nodes take up
    condense: false,
    // uses all available space on false, uses minimal space on true
    rows: void 0,
    // force num of rows in the grid
    cols: void 0,
    // force num of columns in the grid
    position: function() {
    },
    // returns { row, col } for element
    sort: void 0,
    // a sorting function to order the nodes; e.g. function(a, b){ return a.data('weight') - b.data('weight') }
    animate: true,
    // whether to transition the node positions
    animationDuration: 250,
    // duration of animation in ms if enabled
    animationEasing: void 0,
    // easing of animation if enabled
    animateFilter: function() {
      return true;
    },
    // a function that determines whether the node should be animated.  All nodes animated by default on animate enabled.  Non-animated nodes are positioned immediately when the layout starts
    ready: void 0,
    // callback on layoutready
    stop: void 0,
    // callback on layoutstop
    transform: function(node, position) {
      return position;
    }
    // transform a given node position. Useful for changing flow direction in discrete layouts
  };
};
