import React from "react";
import { fetchImage } from "../../../../Utils/ImageSelector";
import { getStateInformation } from "../../../../Utils/Resources/ResourceStateParser";
import LoadBalancerItem from "./LoadBalancerDetails/LoadBalancerItem";
import * as R from "ramda";
export const parseLoadBalancer = (node) => {
  const properties = R.hasPath(["properties"], node) ? node.properties : node.data("properties");
  let configuration = JSON.parse(properties.configuration);
  configuration = R.is(Object, configuration) ? configuration : JSON.parse(configuration);
  const getLoadBalancerType = (properties2) => {
    return configuration.type ? `${properties2.resourceType}-${configuration.type}` : `${properties2.resourceType}`;
  };
  const state = getStateInformation(configuration.state);
  return {
    styling: {
      borderStyle: "dotted",
      borderColour: state.color,
      borderOpacity: 0.25,
      borderSize: 1,
      message: state.text,
      colour: state.color
    },
    state,
    icon: fetchImage(getLoadBalancerType(properties), state),
    detailsComponent: /* @__PURE__ */ React.createElement(
      LoadBalancerItem,
      {
        title: "Load Balancer Details",
        configuration: properties.configuration
      }
    )
  };
};
