import React from "react";
import {
  ColumnLayout,
  SpaceBetween,
  Link
} from "@cloudscape-design/components";
import ValueWithLabel from "../../../../../components/Shared/ValueWithLabel";
const parseConfiguration = (configuration) => {
  try {
    return JSON.parse(JSON.parse(configuration));
  } catch (Error) {
    return JSON.parse(configuration);
  }
};
export const CloudFrontDistributionItem = ({ configuration }) => {
  const parsedConfig = parseConfiguration(configuration);
  return /* @__PURE__ */ React.createElement(ColumnLayout, { columns: 2, variant: "text-grid" }, /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Domain name" }, /* @__PURE__ */ React.createElement(
    Link,
    {
      external: true,
      externalIconAriaLabel: "Opens in a new tab",
      href: `https://${parsedConfig.domainName}`
    },
    `https://${parsedConfig.domainName}`
  )), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Certificate source" }, parsedConfig.distributionConfig.viewerCertificate.certificateSource), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Default root object" }, parsedConfig.distributionConfig.defaultRootObject), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "WAF WebACL attached" }, parsedConfig.distributionConfig.webACLId ? "true" : "false")), /* @__PURE__ */ React.createElement(SpaceBetween, { size: "l" }, /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Smooth streaming" }, parsedConfig.distributionConfig.defaultCacheBehavior.smoothStreaming), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Viewer protocol policy" }, parsedConfig.distributionConfig.defaultCacheBehavior.viewerProtocolPolicy), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Access logging enabled" }, parsedConfig.distributionConfig.logging.enabled), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "Price class" }, parsedConfig.distributionConfig.priceClass), /* @__PURE__ */ React.createElement(ValueWithLabel, { label: "IPv6 enabled" }, parsedConfig.distributionConfig.isIPV6Enabled)));
};
export default CloudFrontDistributionItem;
