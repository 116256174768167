import React from "react";
import { HelpPanel } from "@cloudscape-design/components";
const Welcome = () => {
  return /* @__PURE__ */ React.createElement(
    HelpPanel,
    {
      header: /* @__PURE__ */ React.createElement("h2", null, "AWS Resources")
    },
    /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", null, "You can explore the AWS Resources that Workload Discovery on AWS has discovered."))
  );
};
export default Welcome;
